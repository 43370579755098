import React from 'react';
//Components
import {css} from 'glamor';

export default function DocumentBlock(props) {

    let documentsBlock = css({
        fontSize: "12px",
        ' >a': {
            cursor: "pointer",
            borderRadius: "100px",
            maxHeight: "60px",
            width: "100%",
            maxWidth: "360px",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            boxShadow: "1px 1px 10px rgba(0, 0, 0, 0.05)",
            overflow: "hidden",
            transition: "box-shadow .3s ease, border-color .3s ease",
            ' .document-icon': {
                minWidth: "45px",
                minHeight: "45px",
                borderRadius: "100px",
                boxShadow: "2px 2px 15px 0 rgba(0, 0, 0, 0.05)",
                borderRight: "1px solid rgba(0, 0, 0, 0.1)",
                // border: "1px solid rgba(0, 0, 0, 0.1)",
                background: "none",
                color: "#F19036",
                transition: "color .3s ease, background .3s ease",
            },
            ':hover': {
                boxShadow: "1px 1px 10px 0 rgba(245, 125, 90, 0.1)",
                borderColor: "rgba(241,144,54,0.3)",
                ' .document-icon': {
                    background: "#F19036",
                    color: "white",
                    borderColor: "rgba(241,144,54,0.3)"
                }
            }
        },
        '@media (min-width: 992px) and (min-height: 768px) and (orientation: landscape)': {
            fontSize: "14px",
            ' >div': {
                ' .document-icon': {
                    minWidth: "55px",
                    minHeight: "55px",
                },
            }
        }

    });

    return (
        <div className="d-inline-block m-2" {...documentsBlock}>
            <a target="_blank" rel="noopener noreferrer" href={props.data.file} className="d-flex bg">
                <div className="document-icon d-flex justify-content-center align-items-center">
                    <i className="fa fa-file-pdf-o fa-2x"/>
                </div>
                <div className="d-flex align-items-center ml-2 ml-sm-3 mr-3 mr-sm-4 w-100 text-left">
                    {props.data.name}
                </div>
            </a>
        </div>
    )
};
