import React, {Component} from 'react';
import Reviews from "./reviews";

import {connect} from 'react-redux';
import {reviewsAll} from '../../../actions';
import {bindActionCreators} from "redux";

class ReviewsContainer extends Component {

    componentDidMount() {
        this.showLoadingOverlay();
        this.props.reviewsAll();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.hideLoadingOverlay();
    }

    render() {
        // console.log(this.props);
        return <Reviews reviewsList={this.props.reviews.reviewsList}/>;
    }


    showLoadingOverlay() {
        let overlay = document.querySelector("#reviewsLoadingOverlay");
        if (overlay)
            overlay.classList.add("show");
    }

    hideLoadingOverlay() {
        let overlay = document.querySelector("#reviewsLoadingOverlay");
        if (overlay)
            overlay.classList.remove("show");
    }
}

function mapStateToProps(state) {
    return {
        reviews: state.reviews
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({reviewsAll}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsContainer);

