import React from 'react';
import {css} from "glamor";

export default function Paginator(props) {

    let paginator = css({
        margin: "30px 0",

        ' ul': {
            listStyle: "none",
            padding: 0,
            margin: "0 0px",
            // background: "red",
            ' li': {
                padding: "3px 20px",
                userSelect: "none",
            },
            ' li:not(.disabled):hover': {
                color: "rgba(9,132,62,1) !important",
                borderBottom: "1px solid rgba(9,132,62,1)",
                cursor: "pointer",

            }
        },

        ' .current': {
            color: "rgba(9,132,62,1) !important",
            borderBottom: "1px solid rgba(9,132,62,1)",
            // fontWeight: 600,
        },

        ' .disabled': {
            color: "rgba(0, 0, 0, .5)",
            userSelect: "none",
        },

        ' .icon-box': {
            width: "20px",
            fontSize: "15px",
        },
    });

    let renderPageNumbers = (current, all) => {
        let objects = [];
        let end = (all > 3) ? 3 : all;

        let offset = 0;

        if (current === all) {
            offset = current - end
        } else if (current > 2) {
            offset = current - 2;
        }

        for (let i = 0; i < end; i++) {
            let page = i + 1 + offset;

            objects.push(
                <li key={i} className={`${page === current && 'current'}`} onClick={() => {
                    clickHandler(page)
                }}>
                    <div>{page}</div>
                </li>
            )
        }

        return objects;
    };

    let clickHandler = (item) => {
        if (!item) return null;
        scrollToTop();
        props.settingsChangedHandler({page: item});
    };

    let scrollToTop = () => {
        let topElement = document.getElementById("result-info");
        animateScrollTo(topElement);
    };

    let animateScrollTo = (scrollTo) => {
        let yOffset;
        let top = scrollTo.getBoundingClientRect().top;
        let portrait = window.matchMedia("(orientation: portrait)");

        if (top >= 0 && !portrait.matches)
            yOffset = 0;
        else
            yOffset = -70;

        const y = top + window.pageYOffset + yOffset;
        window.scrollTo({top: y});
    };

    let renderPagination = () => {
        if (!props.pagination) return null;

        let currentPage = props.pagination.currentPage;
        let pagesCount = props.pagination.pagesCount;

        return (
            <ul className="d-flex justify-content-center justify-content-md-end">
                <li className={`${!props.pagination.previous ? 'disabled' : ''}`} onClick={() => {
                    clickHandler(props.pagination.previous && props.pagination.previous.page)
                }}>
                    <div className="d-flex">
                        <div className="icon-box d-flex align-items-center justify-content-center mr-1">
                            <i className="fa fa-caret-left"/>
                        </div>
                        <span>Poprzednia</span>
                    </div>
                </li>

                {renderPageNumbers(currentPage, pagesCount)}

                <li className={`${!props.pagination.next ? 'disabled' : ''}`} onClick={() => {
                    clickHandler(props.pagination.next && props.pagination.next.page)
                }}>
                    <div className="d-flex">
                        <span>Następna</span>
                        <div className="icon-box d-flex align-items-center justify-content-center ml-1">
                            <i className="fa fa-caret-right"/>
                        </div>
                    </div>
                </li>
            </ul>
        )
    };

    return (
        <div className="paginator" {...paginator}>
            <nav>
                {renderPagination()}
            </nav>
        </div>
    );
};