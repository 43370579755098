import React, {Component} from 'react';
import {css} from "glamor";
import ProductsList from "./ProductsList";


export default class CashRegistersCatalog extends Component {

    render() {
        let cashRegistersCatalog = css({
            padding: "50px 0",
            overflow: "hidden",
        });

        return (
            <div className="bg-lightgrey">
                <section id="cash-registers-catalog"
                         className="cash-registers-catalog container text-left" {...cashRegistersCatalog}>

                    {/* Products list */}
                    <ProductsList cashRegisters={this.props.devicesList}/>
                </section>
            </div>
        );
    }

};

