import {combineReducers} from "redux";
import devicesList from './devicesList';
import contractors from "./contractors";
import reviews from "./reviews";

const rootReducer = combineReducers({
    devicesList,
    contractors,
    reviews
});

export default rootReducer