import React, {Component} from 'react';

import {connect} from 'react-redux';
import {contractorsAll} from '../../../actions/';
import {bindActionCreators} from "redux";
import Contractors from "./contractors";

class ContractorsContainer extends Component {

    componentDidMount() {
        this.showLoadingOverlay();
        this.props.contractorsAll();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.hideLoadingOverlay();
    }

    render() {
        // console.log(this.props);
        return <Contractors contractorsList={this.props.contractors.contractorsList}/>;
    }


    showLoadingOverlay() {
        let overlay = document.querySelector("#contractorsLoadingOverlay");
        if (overlay)
            overlay.classList.add("show");
    }

    hideLoadingOverlay() {
        let overlay = document.querySelector("#contractorsLoadingOverlay");
        if (overlay)
            overlay.classList.remove("show");
    }
}

function mapStateToProps(state) {
    return {
        contractors: state.contractors
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({contractorsAll}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractorsContainer);

