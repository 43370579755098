import React from 'react';
//Components
import {css} from 'glamor';
import MenuItems from "./menu-items";
import {Link} from "react-router-dom";

export default function MobileDevicesMenu(props) {

    let mobileDevicesMenu = css({
        position: "fixed",
        zIndex: 1024,
        top: 0,
        left: 0,
        background: "white",
        height: "100vh",
        width: "100vw",
        paddingTop: "100px",
        overflowY: "auto",


        transitionDuration: ".4s",
        transitionProperty: "visibility, opacity",
        transitionDelay: '0',

        ':not(.show)': {
            visibility: "hidden",
            opacity: 0,
            transitionDuration: ".4s",
            transitionProperty: "opacity, visibility",
            transitionDelay: '0, .4s',
        },

        '@media (orientation: landscape)': {},
        '@media (min-width: 600px) and (orientation: landscape)': {},
        '@media (min-width: 768px)': {},
        '@media (min-width: 768px) and (orientation: landscape)': {},
        '@media (min-width: 992px) and (min-height: 768px) and (orientation: landscape)': {}
    });


    let productsSubmenu = css({
        width: "100vw",
        height: "100vh",
        background: 'white',
        overflowX: "hidden",
        overflowY: "auto",
        fontSize: "12px",
        padding: "85px 30px 50px 30px",
        top: 0,
        transitionDuration: ".4s",
        transitionProperty: "visibility, opacity",
        transitionDelay: '0',

        ':not(.show)': {
            visibility: "hidden",
            opacity: 0,
            transitionDuration: ".4s",
            transitionProperty: "opacity, visibility",
            transitionDelay: '0, .4s',
        },

        ' .submenu-item': {
            cursor: "pointer",
            borderBottom: "1px solid rgba(255,255,255,0)",
            ':hover': {
                borderBottom: "1px solid #09843E"
            }
        },

        ' .submenu-help': {
            cursor: "pointer",
        },
        ' h1': {
            fontSize: "11px",
            width: "100%",
            paddingBottom: "5px",
            textAlign: "center",
            textTransform: "uppercase",
            color: "#09843E"
        },
        ' .icon-box': {
            // width: "20px",
            marginRight: "15px",
            fontSize: "12px",
            color: "#09843E",
        },
        ' .backButton': {
            padding: "20px 0 20px 0",
            marginBottom: "10px",
            cursor: "pointer",
            // background: "red",
            ' .icon-box': {
                // width: "20px",
                // background: "pink",
                marginRight: "15px",
                fontSize: "25px",
                color: "#09843E",
                ' .fa': {
                    marginTop: "2px"
                }
            },
        }
    });

    let hideSubmenu = () => {
        let submenu = document.querySelector("#mobile-devices-menu .submenu");
        if (submenu)
            submenu.classList.remove("show");
    };

    let showSubmenu = () => {
        let submenu = document.querySelector("#mobile-devices-menu .submenu");
        if (submenu)
            submenu.classList.add("show");
    };

    let linkClickHandler = (button) => {

        switch (button) {
            case "produkty": {
                showSubmenu();
                break;
            }
            default: {
                props.linkClickHandler();
            }
        }
    };

    let submenuLinkClickHandler = () => {
        hideSubmenu();
        props.linkClickHandler();
    };

    return (
        <div id="mobile-devices-menu" className="mobile-devices-menu" {...mobileDevicesMenu}>

            <div className="p-5">

                {/* Menu Items */}
                <MenuItems linkClickHandler={(button) => linkClickHandler(button)}/>
            </div>

            <div className="position-absolute submenu" {...productsSubmenu}>
                <div className="backButton d-flex align-items-center px-5" onClick={hideSubmenu}>
                    <div className="icon-box d-flex">
                        <i className="fa fa-long-arrow-left"/>
                    </div>
                    <span>Wróć do menu</span>
                </div>
                <nav className="row px-5">
                    <div className="col-12 m-0 mb-5">
                        <h1>Kasy fiskalne</h1>

                        <Link to="/produkty/kasy_fiskalne/małe-mobilne#produkty">
                            <div className="submenu-item p-1 d-flex align-items-center"
                                 onClick={submenuLinkClickHandler}>
                                <div className="icon-box d-flex align-items-center justify-content-center">
                                    <i className="fa fa-caret-right"/>
                                </div>
                                <span>Małe / Mobilne</span>
                            </div>
                        </Link>

                        <Link to="/produkty/kasy_fiskalne/średnie#produkty">
                            <div className="submenu-item p-1 d-flex align-items-center"
                                 onClick={submenuLinkClickHandler}>
                                <div className="icon-box d-flex align-items-center justify-content-center">
                                    <i className="fa fa-caret-right"/>
                                </div>
                                <span>Średnie</span>
                            </div>
                        </Link>

                        <Link to="/produkty/kasy_fiskalne/systemowe#produkty">
                            <div className="submenu-item p-1 d-flex align-items-center"
                                 onClick={submenuLinkClickHandler}>
                                <div className="icon-box d-flex align-items-center justify-content-center">
                                    <i className="fa fa-caret-right"/>
                                </div>
                                <span>Systemowe</span>
                            </div>
                        </Link>

                        <Link to="/produkty/kasy_fiskalne/wszystkie#produkty">
                            <div className="submenu-item p-1 d-flex align-items-center"
                                 onClick={submenuLinkClickHandler}>
                                <div className="icon-box d-flex align-items-center justify-content-center">
                                    <i className="fa fa-caret-right"/>
                                </div>
                                <span>Wszystkie</span>
                            </div>
                        </Link>
                    </div>

                    <div className="col-12 m-0  mb-5">
                        <h1>Drukarki fiskalne</h1>

                        <Link to="/produkty/drukarki_fiskalne/mobilne#produkty">
                            <div className="submenu-item p-1 d-flex align-items-center"
                                 onClick={submenuLinkClickHandler}>
                                <div className="icon-box d-flex align-items-center justify-content-center">
                                    <i className="fa fa-caret-right"/>
                                </div>
                                <span>Mobilne</span>
                            </div>
                        </Link>

                        <Link to="/produkty/drukarki_fiskalne/stacjonarne#produkty">
                            <div className="submenu-item p-1 d-flex align-items-center"
                                 onClick={submenuLinkClickHandler}>
                                <div className="icon-box d-flex align-items-center justify-content-center">
                                    <i className="fa fa-caret-right"/>
                                </div>
                                <span>Stacjonarne</span>
                            </div>
                        </Link>

                        <Link to="/produkty/drukarki_fiskalne/wszystkie#produkty">
                            <div className="submenu-item p-1 d-flex align-items-center"
                                 onClick={submenuLinkClickHandler}>
                                <div className="icon-box d-flex align-items-center justify-content-center">
                                    <i className="fa fa-caret-right"/>
                                </div>
                                <span>Wszystkie</span>
                            </div>
                        </Link>
                    </div>

                    <div className="col-12 m-0 mb-5">
                        <h1>Terminale płatnicze</h1>

                        <Link to="/produkty/terminale_płatnicze/wszystkie#produkty">
                            <div className="submenu-item p-1 d-flex align-items-center"
                                 onClick={submenuLinkClickHandler}>
                                <div className="icon-box d-flex align-items-center justify-content-center">
                                    <i className="fa fa-caret-right"/>
                                </div>
                                <span>Wszystkie</span>
                            </div>
                        </Link>
                    </div>

                    <div className="col-12 m-0 mb-5">
                        <h1>Dla branż</h1>

                        <Link to="/dla-mechanika">
                            <div className="submenu-item p-1 d-flex align-items-center"
                                 onClick={submenuLinkClickHandler}>
                                <div className="icon-box d-flex align-items-center justify-content-center">
                                    <i className="fa fa-caret-right"/>
                                </div>
                                <span>Dla mechaników samochodowych</span>
                            </div>
                        </Link>

                        <Link to="/dla-fryzjera">
                            <div className="submenu-item p-1 d-flex align-items-center"
                                 onClick={submenuLinkClickHandler}>
                                <div className="icon-box d-flex align-items-center justify-content-center">
                                    <i className="fa fa-caret-right"/>
                                </div>
                                <span>Do salonów fryzjerskich</span>
                            </div>
                        </Link>

                        <Link to="/dla-gastronomii">
                            <div className="submenu-item p-1 d-flex align-items-center"
                                 onClick={submenuLinkClickHandler}>
                                <div className="icon-box d-flex align-items-center justify-content-center">
                                    <i className="fa fa-caret-right"/>
                                </div>
                                <span>Dla gastronomii</span>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 m-0 mb-5">
                        <h1>Potrzebujesz pomocy?</h1>

                        <Link to="/#kontakt">
                            <div className="submenu-help p-1 d-flex align-items-center"
                                 onClick={submenuLinkClickHandler}
                                 style={{maxWidth: "200px"}}>
                                <span>Skontaktuj się z nami! Nasi doradcy pomogą Ci wybrać odpowiednią kasę do Twoich potrzeb! :)</span>
                            </div>
                        </Link>
                    </div>
                </nav>
            </div>

        </div>
    )
};
