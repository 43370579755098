import React from 'react';
import {Link} from 'react-router-dom';
//Components
import {css} from 'glamor';

export default function MenuItems(props) {

    let menuItems = css({
        textTransform: "uppercase",
        fontSize: "11px",
        margin: "0 50px",
        padding: "0",
        listStyleType: "none",
        ' .nav-item': {
            borderBottom: "1px solid rgba(0, 0, 0, .1)",
            ' .nav-link': {
                width: "100%",
                textAlign: "center",
                padding: "20px 10px 15px 10px",
            },
        },
        " :last-child": {
            ' .nav-item': {
                borderBottom: "none",
            },
        }
    });

    return (
        <ul className="menu-items-list" {...menuItems}>
            <li className="nav-item d-flex justify-content-center" onClick={() => props.linkClickHandler("produkty")}>
                <span className="nav-link">Produkty</span>
            </li>

            <Link to="/#serwis">
                <li className="nav-item d-flex justify-content-center" onClick={props.linkClickHandler}>
                    <span className="nav-link">Serwis</span>
                </li>
            </Link>

            <Link to="/druki#druki">
                <li className="nav-item d-flex justify-content-center" onClick={props.linkClickHandler}>
                    <span className="nav-link">Druki</span>
                </li>
            </Link>

            <Link to="/#o-nas">
                <li className="nav-item d-flex justify-content-center" onClick={props.linkClickHandler}>
                    <span className="nav-link">O Nas</span>
                </li>
            </Link>

            <Link to="/#kontakt">
                <li className="nav-item d-flex justify-content-center" onClick={props.linkClickHandler}>
                    <span className="nav-link">Kontakt</span>
                </li>
            </Link>
        </ul>
    )
};
