import React, {Component} from 'react';
import {css} from "glamor";
import {Link} from "react-router-dom";


// const CASH_REGISTERS_SIZES = ["małe/mobilne", "średnie", "systemowe"];
// const CASH_REGISTERS_TYPES = ["online", "offline"];
// const CASH_REGISTERS_PRODUCERS = ["novitus", "posnet"];
//
// const FISCAL_PRINTERS_SIZES = ["mobilne", "stacjonarne"];
// const FISCAL_PRINTERS_TYPES = ["online", "offline"];
// const FISCAL_PRINTERS_PRODUCERS = ["novitus", "posnet"];
//
// const PINPADS_PRODUCERS = ["PayTel"];

export default class SearchMenu extends Component {

    CASH_REGISTERS_SIZES = [];
    CASH_REGISTERS_TYPES = [];
    CASH_REGISTERS_PRODUCERS = [];
    FISCAL_PRINTERS_SIZES = [];
    FISCAL_PRINTERS_TYPES = [];
    FISCAL_PRINTERS_PRODUCERS = [];
    PINPADS_PRODUCERS = [];

    state = ({
        device: this.props.dataSource
    });

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.dataSource) {
            this.setState({
                device: nextProps.dataSource
            })
        }
    }

    UNSAFE_componentWillMount() {
        this.CASH_REGISTERS_SIZES = ["małe/mobilne", "średnie", "systemowe"];
        this.CASH_REGISTERS_TYPES = ["online", "offline"];
        this.CASH_REGISTERS_PRODUCERS = ["Novitus", "Posnet"];
        this.FISCAL_PRINTERS_SIZES = ["mobilne", "stacjonarne"];
        this.FISCAL_PRINTERS_TYPES = ["online", "offline"];
        this.FISCAL_PRINTERS_PRODUCERS = ["Novitus", "Posnet"];
        this.PINPADS_PRODUCERS = ["PayTel", "Posnet"];
    }


    componentDidMount() {
        window.addEventListener('click', this.windowClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.windowClick);
    }

    windowClick = (e) => {
        if (!document.querySelector('.search-menu .dropdown-device .dropdown-button').contains(e.target)) {
            let dropdownMenuDevice = document.querySelector(".search-menu .dropdown-device .dropdown-menu");
            dropdownMenuDevice.classList.remove("show");
        }
        if (!document.querySelector('.search-menu .dropdown-special .dropdown-button').contains(e.target)) {
            let dropdownMenuDevice = document.querySelector(".search-menu .dropdown-special .dropdown-menu");
            dropdownMenuDevice.classList.remove("show");
        }
    };


    dropdownButtonClickHandlerDevice = () => {
        let dropdownMenu = document.querySelector(".search-menu .dropdown-device .dropdown-menu");
        dropdownMenu.classList.toggle("show");
    };

    dropdownButtonClickHandlerSpecial = () => {
        let dropdownMenu = document.querySelector(".search-menu .dropdown-special .dropdown-menu");
        dropdownMenu.classList.toggle("show");
    };

    dropdownItemClickHandler = (type) => {
        let dropdownMenu = document.querySelector(".search-menu .dropdown-menu");
        dropdownMenu.classList.remove("show");

        if (this.state.device !== type) {
            this.setState({
                device: type
            });

            this.props.changeDataSourceHandler(type);
        }

    };

    itemClickHandler = (parent, item) => {

        try {


            switch (parent) {
                case "size": {
                    let result = [];
                    this.props.settings.size.forEach((x) => {
                        if (x !== "all" && x !== item)
                            result.push(x)
                    });

                    if (!this.props.settings.size.includes(item)) result.push(item);

                    result = result.join(',');
                    if (result === "") result = null;

                    this.props.settingsChangedHandler({size: result});
                    return;
                }
                case "type": {
                    let result = [];
                    this.props.settings.type.forEach((x) => {
                        if (x !== "all" && x !== item)
                            result.push(x)
                    });

                    if (!this.props.settings.type.includes(item)) result.push(item);

                    result = result.join(',');
                    if (result === "") result = null;

                    this.props.settingsChangedHandler({type: result});
                    return;
                }
                case "producer": {
                    let result = [];
                    this.props.settings.producer.forEach((x) => {
                        if (x !== "all" && x !== item)
                            result.push(x)
                    });

                    if (!this.props.settings.producer.includes(item)) result.push(item);

                    result = result.join(',');
                    if (result === "") result = null;

                    this.props.settingsChangedHandler({producer: result});
                    return;
                }
                default: {
                    console.error("Wrong item parent!")
                }

            }
        } catch (e) {
            // console.error(e.message);
        }
    };

    render() {

        let searchMenu = css({
            minHeight: "1px",
            transition: "all 1s ease",

            ' .dropdown-button': {
                background: "none",
                border: "none",
                color: "rgba(9,132,62,1)",
                fontWeight: "bold"
            },
            ' .dropdown-menu': {
                ' .dropdown-item': {
                    userSelect: 'none',
                    cursor: "pointer"
                }
            },
            ' .icon-box': {
                width: "20px",
                marginLeft: "10px",
                fontSize: "15px",
            },

            ' .details': {
                ' .items': {
                    ' .active': {
                        ' .fa-square-o': {
                            display: "none"
                        },
                        ' .fa-check-square-o': {
                            display: "block"
                        }
                    },
                    ' .item': {
                        margin: 0,
                        cursor: "pointer",
                        padding: "0 10px",
                        borderBottom: "1px solid rgba(9,132,62,0)",
                        userSelect: "none",
                        ':not(.active)': {
                            ' .fa-square-o': {
                                display: "block"
                            },
                            ' .fa-check-square-o': {
                                display: "none"
                            },
                        },
                        ':hover': {
                            // background: "rgba(0, 0, 0, .01)",
                            borderBottom: "1px solid rgba(9,132,62,1)",
                            ' .icon-box': {
                                color: "rgba(9,132,62,1)"
                            }
                        }
                    }
                },
                ' .icon-box': {
                    width: "20px",
                    marginRight: "0",
                    marginLeft: "0",
                    fontSize: "13px",
                    ' .fa': {
                        marginTop: "3px"
                    }
                },
            }
        });

        return (
            <div className="search-menu row mx-1 mx-md-0" {...searchMenu}>
                <div className="col-12 col-md-4 col-lg-5 mb-3 mb-md-0 d-block align-items-start p-0 m-0 ">
                    <div className="dropdown dropdown-device">
                        <button className="d-flex dropdown-button text-uppercase align-items-center" type="button"
                                onClick={this.dropdownButtonClickHandlerDevice}>
                            <span>{this.state.device}</span>
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <i className="fa fa-caret-down"/>
                            </div>
                        </button>

                        {/*add show class*/}
                        <div className="dropdown-menu">
                            <Link to="/#produkty">
                                <div className="dropdown-item" onClick={() => {
                                    this.dropdownItemClickHandler("Kasy fiskalne")
                                }}>Kasy fiskalne
                                </div>
                            </Link>
                            <Link to="/#produkty">
                                <div className="dropdown-item" onClick={() => {
                                    this.dropdownItemClickHandler("Drukarki fiskalne")
                                }}>Drukarki fiskalne
                                </div>
                            </Link>
                            <Link to="/#produkty">
                                <div className="dropdown-item" onClick={() => {
                                    this.dropdownItemClickHandler("Terminale płatnicze")
                                }}>Terminale płatnicze
                                </div>
                            </Link>
                        </div>
                    </div>


                    <div className="dropdown dropdown-special mt-2">
                        <button className="d-flex dropdown-button text-uppercase align-items-center" type="button"
                                onClick={this.dropdownButtonClickHandlerSpecial}>
                            <span>Do twojego biznesu:</span>
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <i className="fa fa-caret-down"/>
                            </div>
                        </button>

                        {/*add show class*/}
                        <div className="dropdown-menu">
                            <Link to="/dla-mechanika">
                                <div className="dropdown-item" >Kasy fiskalne dla mechaników
                                </div>
                            </Link>
                            <Link to="/dla-fryzjera">
                                <div className="dropdown-item">Kasy fiskalne dla fryzjerów
                                </div>
                            </Link>
                            <Link to="/dla-gastronomii">
                                <div className="dropdown-item">Kasy fiskalne dla gastronomii
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>


                {this.renderDeviceMenu()}
            </div>
        );
    }

    renderDeviceMenu() {
        let device = this.state.device;
        switch (device) {
            case "Kasy fiskalne": {
                return this.renderCashRegisters();
            }
            case 'Drukarki fiskalne': {
                return this.renderFiscalPrinter();
            }
            case 'Terminale płatnicze': {
                return this.renderPinPad();
            }
            default: {
                console.error("Wrong device!")
            }
        }
    }

    renderItem(collection, parent) {
        let result = [];

        let test = (item) => {
            if (this.props.settings) {
                switch (parent) {
                    case "size": {
                        if (!this.props.settings.size) return false;
                        return this.props.settings.size.includes("all") || this.props.settings.size.includes(item);
                    }
                    case "type": {
                        if (!this.props.settings.type) return false;
                        return this.props.settings.type.includes("all") || this.props.settings.type.includes(item);
                    }
                    case "producer": {
                        // console.log(this.props.settings.producer);
                        if (!this.props.settings.producer) return false;
                        return this.props.settings.producer.includes("all") || this.props.settings.producer.includes(item);
                    }
                    default: {
                        return false
                    }
                }
            }
            return false;
        };

        collection.forEach((item, i) => {
            result.push(
                <div key={i} className={`item d-flex ${!this.props.settings ? 'active' : (test(item) ? 'active' : '')}`}
                     onClick={() => this.itemClickHandler(parent, item)}>
                    <div className="icon-box d-flex align-items-center justify-content-center">
                        <i className="fa fa-check-square-o"/>
                        <i className="fa fa-square-o"/>
                    </div>
                    <span>{item}</span>
                </div>
            )

        });

        return result;
    }

    renderCashRegisters() {
        return (
            <div className="col-12 col-md-8 col-lg-7 details">
                <div className="row">

                    <div className="col-12 col-md-4 py-2 py-md-0 row m-0 px-0">
                        <div
                            className="col-6 col-sm-7 col-md-12 d-md-none d-flex justify-content-end justify-content-md-start px-0">
                            <div className="label font-weight-bold">typ:</div>
                        </div>
                        <div className="col-6 col-sm-5 col-md-12 d-flex d-md-block d-lg-flex justify-content-start p-0">
                            <div className="label d-none d-md-block font-weight-bold my-2">typ:</div>
                            <div className="items my-lg-2">
                                {this.renderItem(this.CASH_REGISTERS_SIZES, "size")}
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 py-2 py-md-0 row m-0 px-0">
                        <div
                            className="col-6 col-sm-7 col-md-12 d-md-none d-flex justify-content-end justify-content-md-start px-0">
                            <div className="label font-weight-bold">rodzaj:</div>
                        </div>
                        <div className="col-6 col-sm-5 col-md-12 d-flex d-md-block d-lg-flex justify-content-start p-0">
                            <div className="label d-none d-md-block font-weight-bold my-2">rodzaj:</div>
                            <div className="items my-lg-2">
                                {this.renderItem(this.CASH_REGISTERS_TYPES, "type")}
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 py-2 py-md-0 row m-0 px-0">
                        <div
                            className="col-6 col-sm-7 col-md-12 d-md-none d-flex justify-content-end justify-content-md-start px-0">
                            <div className="label font-weight-bold">producent:</div>
                        </div>
                        <div className="col-6 col-sm-5 col-md-12 d-flex d-md-block d-lg-flex justify-content-start p-0">
                            <div className="label d-none d-md-block font-weight-bold my-2">producent:</div>
                            <div className="items my-lg-2">
                                {this.renderItem(this.CASH_REGISTERS_PRODUCERS, "producer")}
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        )
    }


    renderFiscalPrinter() {
        return (
            <div className="col-12 col-md-8 col-lg-7 details">
                <div className="row">
                    <div className="col-12 col-md-4 py-2 py-md-0 row m-0 px-0">
                        <div
                            className="col-6 col-sm-7 col-md-12 d-md-none d-flex justify-content-end justify-content-md-start px-0">
                            <div className="label font-weight-bold">typ:</div>
                        </div>
                        <div className="col-6 col-sm-5 col-md-12 d-flex d-md-block d-lg-flex justify-content-start p-0">
                            <div className="label d-none d-md-block font-weight-bold my-2">typ:</div>
                            <div className="items my-lg-2">
                                {this.renderItem(this.FISCAL_PRINTERS_SIZES, "size")}
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 py-2 py-md-0 row m-0 px-0">
                        <div
                            className="col-6 col-sm-7 col-md-12 d-md-none d-flex justify-content-end justify-content-md-start px-0">
                            <div className="label font-weight-bold">rodzaj:</div>
                        </div>
                        <div className="col-6 col-sm-5 col-md-12 d-flex d-md-block d-lg-flex justify-content-start p-0">
                            <div className="label d-none d-md-block font-weight-bold my-2">rodzaj:</div>
                            <div className="items my-lg-2">
                                {this.renderItem(this.FISCAL_PRINTERS_TYPES, "type")}
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 py-2 py-md-0 row m-0 px-0">
                        <div
                            className="col-6 col-sm-7 col-md-12 d-md-none d-flex justify-content-end justify-content-md-start px-0">
                            <div className="label font-weight-bold">producent:</div>
                        </div>
                        <div className="col-6 col-sm-5 col-md-12 d-flex d-md-block d-lg-flex justify-content-start p-0">
                            <div className="label d-none d-md-block font-weight-bold my-2">producent:</div>
                            <div className="items my-lg-2">
                                {this.renderItem(this.FISCAL_PRINTERS_PRODUCERS, "producer")}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }


    renderPinPad() {
        return (
            <div className="col-12 col-md-8 col-lg-7 details">
                <div className="row">
                    <div className="col-12 col-md-4 d-flex">

                    </div>

                    <div className="col-12 col-md-4 d-flex">

                    </div>

                    <div className="col-12 col-md-4 py-2 py-md-0 row m-0 px-0">
                        <div
                            className="col-6 col-sm-7 col-md-12 d-md-none d-flex justify-content-end justify-content-md-start px-0">
                            <div className="label font-weight-bold">producent:</div>
                        </div>
                        <div className="col-6 col-sm-5 col-md-12 d-flex d-md-block d-lg-flex justify-content-start p-0">
                            <div className="label d-none d-md-block font-weight-bold my-2">producent:</div>
                            <div className="items my-lg-2">
                                {this.renderItem(this.PINPADS_PRODUCERS, "producer")}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
};