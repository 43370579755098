import React from 'react';
import {css} from "glamor";
import Item from "./Item";

export default function ProductsList(props) {

    let productList = css({
        margin: "0 -10px",
        minHeight: "300px"
    });

    let loadingOverlay = css({

        top: 0,
        width: "100%",
        height: "100%",
        // minHeight: "300px",
        background: "rgba(0, 0, 0, .4)",
        color: "white",

        transitionDuration: "0",
        transitionProperty: "visibility, opacity",
        transitionDelay: '0',

        ":not(.show)": {
            visibility: "hidden",
            opacity: 0,
            transitionDuration: "1s",
            transitionProperty: "opacity, visibility",
            transitionDelay: '0, 1s',
        }
    });


    let renderProducts = () => {
        // console.log(props);
        // if (!props.cashRegisters || props.cashRegisters.length < 1) return null;
        try {
            return (
                props.cashRegisters.map(function (item, i) {
                    return (
                        <Item item={item} key={i}/>
                    )
                })
            )
        } catch (e) {
            // console.error(e.message.message);
            return (
                <div style={{width: "100%", textAlign: "left", padding: "20px"}}>
                    Wystąpił błąd podczas ładowania produktów. Spróbuj odświeżyć stronę
                </div>
            )
        }
    };

    return (
        <div className="products-list position-relative" {...productList}>
            <div className="row m-0 p-0">
                {renderProducts()}
            </div>
            <div id="productsLoadingOverlay"
                 className="position-absolute d-flex align-items-center justify-content-center" {...loadingOverlay}>
                Loading...
            </div>
        </div>
    );
};