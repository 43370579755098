import React, {Component} from 'react';

import {connect} from 'react-redux';
import {getDevicesForHairdresser} from '../../../../actions';
import {bindActionCreators} from "redux";
import CashRegistersCatalog from "./cash_registers_catalog";

class CashRegistersCatalogContainer extends Component {

    componentDidMount() {
        this.showLoadingOverlay();
        this.props.getDevicesForHairdresser();
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        this.hideLoadingOverlay();

    }

    render() {
        console.log(this.props);
        return (
            <CashRegistersCatalog
                devicesList={this.props.devicesList}
            />
        );
    }


    showLoadingOverlay() {
        let overlay = document.querySelector("#reviewsLoadingOverlay");
        if (overlay)
            overlay.classList.add("show");
    }

    hideLoadingOverlay() {
        let overlay = document.querySelector("#reviewsLoadingOverlay");
        if (overlay)
            overlay.classList.remove("show");
    }
}

function mapStateToProps(state) {
    return {
        devicesList: state.devicesList.devicesList,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({getDevicesForHairdresser}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CashRegistersCatalogContainer);
