import React from 'react';
//Components
import {css} from 'glamor';

export default function ContactInformation(props) {

    let contactInformation = css({
        position: "relative",
        // background: "red",
        fontSize: "12px",
        height: "40px",
        paddingTop: "10px",
        display: "flex !important",
        transform: "translateY(0)",
        ' .icons': {
            display: "none "
        },
        ' .icon-box': {
            width: "20px",
            marginRight: "10px",
            fontSize: "15px",
            ' .fa-mobile': {
                fontSize: "20px"
            }
        },

        '@media (min-width: 576px)': {},
        '@media (min-width: 768px)': {
            ' .icons': {
                // display: "flex"
            }
        },
        '@media (min-width: 992px)': {}
    });

    return (
        <div className="contact-information d-flex"
             {...contactInformation}>

            <div className="email d-flex align-items-center mr-4">
                <div className="icon-box d-flex align-items-center justify-content-center">
                    <i className="fa fa-envelope-o"/>
                </div>
                <span>kasy@komfox.pl</span>
            </div>
            <div className="phone d-flex align-items-center ">
                <div className="icon-box d-flex align-items-center justify-content-center">
                    <i className="fa fa-mobile"/>
                </div>
                <span>+48 71 795 10 44</span>
            </div>

            <div className="icons align-items-center ml-auto">
                <div className="icon-box d-flex align-items-center justify-content-center">
                    <i className="fa fa-instagram"/>
                </div>
                <div className="icon-box d-flex align-items-center justify-content-center">
                    <i className="fa fa-facebook"/>
                </div>
                <div className="icon-box d-flex align-items-center justify-content-center">
                    <i className="fa fa-map-marker"/>
                </div>
            </div>
        </div>
    )
};
