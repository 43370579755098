import React, {Component} from 'react';
//Components
import {css} from 'glamor';
import InfiniteCarousel from "react-leaf-carousel";
import CarMechanicAdd from "./car_mechanic";
import HotelsAndGastronomyAdd from "./holets_and_gastronomy";
import HairdressersAndOthersAdd from "./hairdressers_and_others";
import ServiceAdd from "./service";
import PinpadsAd from "./pinpads";
import DiscountAd from "./promotion";
import adBackground from "../../../assets/background.jpg";

export default class AdSection extends Component {

    adSection = css({


        // backgroundColor: "#E4E4E4",
        minHeight: "100vh",
        ' .carousel': {
            "::before": {
                content: "sd",
                backgroundImage: `url("${adBackground}")`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                // filter: "blur(2px)",
                opacity: 0.35,
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
            },
            backgroundColor: "#cfcfcf",
            height: "100%",
            width: "100%",
            ' button:focus': {
                border: "none",
                outline: "none",
            },
            ' div>button': {
                marginTop: "70px",
                '>i': {
                    border: "solid #F19036",
                    borderWidth: "0 5px 5px 0"
                },
            },
            ' ul>button>i': {
                backgroundColor: "#F19036",
                ":not(._2WFZTqYBc3N8MmmhMS5Dii)": {
                    backgroundColor: "rgba(0, 0, 0, .3)"
                }
            },
            ' >div>div ul': {
                pointerEvents: "none",
                userDrag: "none",
                touchAction: "none"
            },
            ' .item': {
                minHeight: "100vh",
                width: "100%",
                position: "relative",
            }
        }
    });

    render() {

        let renderCarousel = () => {
            return (
                <InfiniteCarousel
                    breakpoints={[
                        {
                            breakpoint: 0,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                scrollOnDevice: false
                            },
                        },
                    ]}
                    dots={true}
                    arrows={true}
                    // showSides={true}
                    slidesSpacing={0}
                    // sidesOpacity={1}
                    pauseOnHover={true}
                    // sideSize={0}
                    animationDuration={998}
                    autoCycle={true}
                    cycleInterval={15000}
                    slidesToScroll={1}
                    slidesToShow={1}
                    scrollOnDevice={false}>

                    <div className="item d-flex align-items-center justify-content-center"
                         style={{touchAction: "none"}}>
                        <HotelsAndGastronomyAdd/>
                    </div>
                    <div className="item d-flex align-items-center justify-content-center"
                         style={{touchAction: "none"}}>
                        <HairdressersAndOthersAdd/>
                    </div>
                    <div className="item d-flex align-items-center justify-content-center"
                         style={{touchAction: "none"}}>
                        <CarMechanicAdd/>
                    </div>
                    <div className="item d-flex align-items-center justify-content-center"
                         style={{touchAction: "none"}}>
                        <ServiceAdd/>
                    </div>
                    <div className="item d-flex align-items-center justify-content-center"
                         style={{touchAction: "none"}}>
                        <PinpadsAd/>
                    </div>
                    {/*<div className="item d-flex align-items-center justify-content-center"*/}
                    {/*     style={{touchAction: "none"}}>*/}
                    {/*    <DiscountAd/>*/}
                    {/*</div>*/}

                </InfiniteCarousel>
            )
        };


        return (
            <section id="main-ad-section" {...this.adSection}>
                <div className="carousel">
                    {renderCarousel()}
                </div>
            </section>
        );
    }
};
