import React from 'react';
import {css} from "glamor";
import {Link} from "react-router-dom";


export default function ProductsSubmenu(props) {

    let submenu = css({

        // width: "100%",
        // transform: "translateX(0%)",
        visibility: "hidden",

        minHeight: "300px",
        background: 'white',
        boxShadow: "1px 5px 5px 0 rgba(0, 0, 0, 0.1)",
        zIndex: "5000",
        fontSize: "11px",
        transitionDuration: ".4s",
        transitionProperty: "visibility, opacity",
        transitionDelay: '0',

        '@media (min-width: 576px)': {
            width: "100vw",
            transform: "translateX(0vw)",
            visibility: "visible",
            paddingRight: "30px",
            paddingLeft: "30px",
            ' h1': {
                fontSize: "11px",
            },
        },
        '@media (min-width: 768px)': {
            width: "70vw",
            transform: "translateX(30vw)",
            visibility: "visible",
            paddingRight: "10px",
            paddingLeft: "20px",
        },
        '@media (min-width: 992px)': {
            fontSize: "12px",
            paddingRight: "50px",
            paddingLeft: "50px",
            ' h1': {
                fontSize: "12px",
            },
        },
        '@media (min-width: 1200px)': {
            width: "50vw",
            transform: "translateX(50vw)",
            paddingRight: "20px",
            paddingLeft: "20px",
        },
        '@media (min-width: 1500px)': {
            width: "50vw",
            transform: "translateX(50vw)",
            paddingRight: "10%",
            paddingLeft: "50px",
        },


        ':not(.show)': {
            visibility: "hidden",
            opacity: 0,
            transitionDuration: ".4s",
            transitionProperty: "opacity, visibility",
            transitionDelay: '0, .4s',
        },

        ' .submenu-item': {
            cursor: "pointer",
            borderBottom: "1px solid rgba(255,255,255,0)",
            ':hover': {
                borderBottom: "1px solid #09843E"
            }
        },
        ' .submenu-help': {
            cursor: "pointer",
        },
        ' h1': {
            fontSize: "12px",
            textTransform: "uppercase",
            color: "#09843E"
        },
        ' .icon-box': {
            // width: "20px",
            marginRight: "15px",
            fontSize: "12px",
            color: "#09843E",
        }
    });

    return (
        <div className="position-absolute submenu" {...submenu}>
            <nav className="row p-5">
                <div className="col-4 m-0 mb-4">
                    <h1>Kasy fiskalne</h1>

                    <Link to="/produkty/kasy_fiskalne/małe-mobilne#produkty">
                        <div className="submenu-item p-1 d-flex align-items-center" onClick={props.hideSubmenu}>
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <i className="fa fa-caret-right"/>
                            </div>
                            <span>Proste / Mobilne</span>
                        </div>
                    </Link>


                    <Link to="/produkty/kasy_fiskalne/średnie#produkty">
                        <div className="submenu-item p-1 d-flex align-items-center" onClick={props.hideSubmenu}>
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <i className="fa fa-caret-right"/>
                            </div>
                            <span>Średnie</span>
                        </div>
                    </Link>


                    <Link to="/produkty/kasy_fiskalne/systemowe#produkty">
                        <div className="submenu-item p-1 d-flex align-items-center" onClick={props.hideSubmenu}>
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <i className="fa fa-caret-right"/>
                            </div>
                            <span>Systemowe</span>
                        </div>
                    </Link>

                    <Link to="/produkty/kasy_fiskalne/wszystkie#produkty">
                        <div className="submenu-item p-1 d-flex align-items-center" onClick={props.hideSubmenu}>
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <i className="fa fa-caret-right"/>
                            </div>
                            <span>Wszystkie</span>
                        </div>
                    </Link>
                </div>

                <div className="col-4 m-0  mb-4">
                    <h1>Drukarki fiskalne</h1>

                    <Link to="/produkty/drukarki_fiskalne/mobilne#produkty">
                        <div className="submenu-item p-1 d-flex align-items-center" onClick={props.hideSubmenu}>
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <i className="fa fa-caret-right"/>
                            </div>
                            <span>Mobilne</span>
                        </div>
                    </Link>

                    <Link to="/produkty/drukarki_fiskalne/stacjonarne#produkty">
                        <div className="submenu-item p-1 d-flex align-items-center" onClick={props.hideSubmenu}>
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <i className="fa fa-caret-right"/>
                            </div>
                            <span>Stacjonarne</span>
                        </div>
                    </Link>

                    <Link to="/produkty/drukarki_fiskalne/wszystkie#produkty">
                        <div className="submenu-item p-1 d-flex align-items-center" onClick={props.hideSubmenu}>
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <i className="fa fa-caret-right"/>
                            </div>
                            <span>Wszystkie</span>
                        </div>
                    </Link>
                </div>

                <div className="col-4 m-0 mb-4">
                    <h1>Terminale płatnicze</h1>

                    <Link to="/produkty/terminale_płatnicze/wszystkie#produkty">
                        <div className="submenu-item p-1 d-flex align-items-center" onClick={props.hideSubmenu}>
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <i className="fa fa-caret-right"/>
                            </div>
                            <span>Wszystkie</span>
                        </div>
                    </Link>
                </div>

                <div className="col-6 m-0 mb-4">
                    <h1>Dla branż</h1>

                    <Link to="/dla-mechanika">
                        <div className="submenu-item p-1 d-flex align-items-center" onClick={props.hideSubmenu}>
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <i className="fa fa-caret-right"/>
                            </div>
                            <span>Dla mechaników samochodowych</span>
                        </div>
                    </Link>

                    <Link to="/dla-fryzjera">
                        <div className="submenu-item p-1 d-flex align-items-center" onClick={props.hideSubmenu}>
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <i className="fa fa-caret-right"/>
                            </div>
                            <span>Do salonów fryzjerskich</span>
                        </div>
                    </Link>

                    <Link to="/dla-gastronomii">
                        <div className="submenu-item p-1 d-flex align-items-center" onClick={props.hideSubmenu}>
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <i className="fa fa-caret-right"/>
                            </div>
                            <span>Dla gastronomii</span>
                        </div>
                    </Link>
                </div>
                <div className="col-6 m-0 mb-4">
                    <h1>Potrzebujesz pomocy?</h1>

                    <Link to="/#kontakt">
                        <div className="submenu-help p-1 d-flex align-items-center" style={{maxWidth: "200px"}}
                             onClick={props.hideSubmenu}>
                            <span>Skontaktuj się z nami! Nasi doradcy pomogą Ci wybrać odpowiednią kasę do Twoich potrzeb! :)</span>
                        </div>
                    </Link>
                </div>
            </nav>
        </div>
    );
};