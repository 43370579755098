import React, {Component} from 'react';
import {css} from "glamor";

export default class ResultInformation extends Component {

    componentDidMount() {
        window.addEventListener('click', this.windowClick);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.windowClick);
    }

    windowClick = (e) => {
        if (!document.querySelector('.result-info .dropdown').contains(e.target)) {
            let dropdownMenu = document.querySelector(".result-info .dropdown-menu");
            dropdownMenu.classList.remove("show");
        }
    };

    dropdownButtonClickHandler = () => {
        let dropdownMenu = document.querySelector(".result-info .dropdown-menu");
        dropdownMenu.classList.toggle("show");
    };

    dropdownItemClickHandler = (type) => {
        let dropdownMenu = document.querySelector(".result-info .dropdown-menu");
        dropdownMenu.classList.remove("show");

        switch (type) {
            case "Najtańsze": {
                this.props.settingsChangedHandler({orderBy: "price", order: "asc"});
                return;
            }
            case "Najdroższe": {
                this.props.settingsChangedHandler({orderBy: "price", order: "desc"});
                return;
            }
            case "Polecane": {
                this.props.settingsChangedHandler({orderBy: "id", order: "asc"});
                return;
            }
            default: {
                console.error("Wrong sort type!")
            }
        }
    };

    searchResultInfo = css({
        fontSize: "12px",
        padding: "20px 0",
        ' .dropdown-button': {
            marginLeft: "20px",
            background: "none",
            border: "none",
            fontWeight: "bold"
        },
        ' .dropdown-menu': {
            ' .dropdown-item': {
                userSelect: 'none',
                cursor: "pointer"
            }
        },
        ' .icon-box': {
            width: "20px",
            marginLeft: "10px",
            fontSize: "12px",
        },
    });

    renderSort = () => {
        if (!this.props.sorting) return "Polecane";
        if (this.props.sorting.by === "price" && this.props.sorting.dir === "asc")
            return "Najtańsze";
        else if (this.props.sorting.by === "price" && this.props.sorting.dir === "desc")
            return "Najdroższe";
        else
            return "Polecane";
    };

    render() {


        return (
            <div id="result-info" className="result-info d-flex" {...this.searchResultInfo}>
                <div className="search-counter">Pasujących produktów: {this.props.resultCount}</div>
                <div className="sort ml-auto">
                    <div className="dropdown">
                        <button className="d-flex dropdown-button align-items-center" type="button"
                                onClick={this.dropdownButtonClickHandler}>
                            <span>Sortuj: <span style={{textTransform: "lowercase"}}>{this.renderSort()}</span></span>
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <i className="fa fa-caret-down"/>
                            </div>
                        </button>

                        {/*add show class*/}
                        <div className="dropdown-menu">
                            <div className="dropdown-item" onClick={() => {
                                this.dropdownItemClickHandler("Najtańsze")
                            }}>Najtańsze
                            </div>
                            <div className="dropdown-item" onClick={() => {
                                this.dropdownItemClickHandler("Najdroższe")
                            }}>Najdroższe
                            </div>
                            <div className="dropdown-item" onClick={() => {
                                this.dropdownItemClickHandler("Polecane")
                            }}>Polecane
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};