import React from 'react';
import {Link} from 'react-router-dom';
//Components
import {css} from 'glamor';
// ASSETS
import logo from '../../../../assets/logo-lis.png';

export default function NavbarLogo(props) {

    let navbarLogo = css({
        opacity: "1",
        width: `200px`,
        position: "absolute",
        // background: "blue",
        top: "65px",
        transition: "opacity .6s ease",
        '@media (min-width: 992px)': {
            width: `250px`,
            top: "60px",
        }
    });

    let scrolledNavbarLogo = css({
        '@media (min-width: 576px)': {
            opacity: "1",
        },
    });

    let hiddenNavbarLogo = css({
        opacity: "0 !important",
    });

    let onClick = () => {

        props.hideSubmenu();
        props.hideMobileMenu();
    };

    return (
        <Link to="/">

            <img className="img-fluid navbar-logo" onClick={onClick}
                 {...navbarLogo}
                 {...(props.changedNavbar) && {...scrolledNavbarLogo}}
                 {...(props.hiddenNavbar) && {...hiddenNavbarLogo}}
                 alt="Kasy i drukarki fiskalne oraz terminale płatnicze | KomFOX - Środa Śląska"
                 src={logo}/>

        </Link>
    )
};
