import React, {Component} from 'react';
import {css} from "glamor";
import SearchMenu from "./search_menu";
import ResultInformation from "./result_information";
import ProductsList from "./ProductsList";
import Paginator from "./paginator";


export default class CashRegistersCatalog extends Component {

    devicesList = null;
    pagination = null;
    resultCount = 0;
    sorting = null;
    settings = null;

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.devicesList && nextProps.devicesList.rows && nextProps.devicesList.pagination) {
            this.devicesList = nextProps.devicesList.rows;
            this.pagination = nextProps.devicesList.pagination;
            this.resultCount = nextProps.devicesList.count;
            this.sorting = nextProps.devicesList.sorting;
            this.settings = nextProps.devicesList.settings;
        }

        // console.log("TEST")
    }

    changeDataSourceHandler(newSource) {
        this.props.changeDataSource(newSource);
    }

    settingsChangedHandler(settings) {
        // console.log(settings);
        this.props.reloadData(settings);
    }

    render() {

        let cashRegistersCatalog = css({
            padding: "50px 0",
            overflow: "hidden",
        });

        let separator = css({
            width: "100%",
            height: "1px",
            margin: "0",
            padding: "0",
            marginTop: "30px",
            background: "#ccc"
        });

        return (
            <div className="bg-lightgrey">
                <section id="cash-registers-catalog"
                         className="cash-registers-catalog container" {...cashRegistersCatalog}>

                    {/* Search menu */}
                    <SearchMenu
                        settings={this.settings}
                        menuItems={this.props.menuItems}
                        dataSource={this.props.dataSource}
                        settingsChangedHandler={(settings) => this.settingsChangedHandler(settings)}
                        changeDataSourceHandler={(newSource) => this.changeDataSourceHandler(newSource)}/>

                    {/* Separator */}
                    <div className="separator" {...separator}/>

                    {/* Result information */}
                    <ResultInformation
                        sorting={this.sorting}
                        resultCount={this.resultCount}
                        settingsChangedHandler={(settings) => this.settingsChangedHandler(settings)}
                    />

                    {/* Products list */}
                    <ProductsList cashRegisters={this.devicesList}/>

                    {/* Paginator */}
                    <Paginator pagination={this.pagination}
                               settingsChangedHandler={(settings) => this.settingsChangedHandler(settings)}/>
                </section>
            </div>
        );
    }

};

