import React, {Component} from 'react';
// Components
import Navbar from './navbar';
import AdSection from './ad_section';
import MobileDevicesMenu from "./mobile-devices-menu";

export default class Header extends Component {

    state = {
        isMobileDevicesMenuHidden: true
    };

    menuButtonClickHandler = () => {
        this.toggleMobileDeviceMenu();
        this.setState({
            isMobileDevicesMenuHidden: !this.state.isMobileDevicesMenuHidden
        });
    };


    linkClickHandler = () => {
        this.hideMobileDeviceMenu();
        this.setState({
            isMobileDevicesMenuHidden: !this.state.isMobileDevicesMenuHidden
        });
    };

    hideMobileDeviceMenu = () => {
        let menu = document.getElementById("mobile-devices-menu");
        menu.classList.remove("show");
    };

    toggleMobileDeviceMenu = () => {
        let menu = document.getElementById("mobile-devices-menu");
        menu.classList.toggle("show");
    };

    render() {
        return (
            <header id="header">

                {/* Navbar */}
                <Navbar
                    isMobileDevicesMenuHidden={this.state.isMobileDevicesMenuHidden}
                    menuButtonClickHandler={() => this.menuButtonClickHandler()}/>

                {/* Hero Image */}
                <AdSection/>

                {/* Mobile devices menu */}
                <MobileDevicesMenu
                    linkClickHandler={this.linkClickHandler}/>
            </header>
        );
    }


    componentDidMount() {
        window.addEventListener('orientationchange', this.hideMenuWhileResizing);
    }

    componentWillUnmount() {
        window.removeEventListener('orientationchange', this.hideMenuWhileResizing);
    }

    hideMenuWhileResizing = () => {
        if (!this.state.isMobileDevicesMenuHidden)
            this.menuButtonClickHandler();
    };

};
