export default function (state = {}, action) {

    switch (action.type) {
        case 'GET_CASH_REGISTERS':
            return {...state, devicesList: action.payload};
        case 'GET_DEVICES_FOR_CAR_MECHANIC':
            return {...state, devicesList: action.payload};
        case 'GET_DEVICES_FOR_HAIRDRESSER':
            return {...state, devicesList: action.payload};
        case 'GET_DEVICES_FOR_GASTRONOMY':
            return {...state, devicesList: action.payload};
        case 'GET_FISCAL_PRINTERS':
            return {...state, devicesList: action.payload};
        case 'GET_PINPADS':
            return {...state, devicesList: action.payload};
        case 'CLEAR_PRODUCTS':
            return {...state, devicesList: action.payload};
        default:
            return state;
    }
}