import React, {Component} from 'react';
import {css} from "glamor";
import CashRegistersCatalog from "./cash_registers_catalog";

export default class ForGastronomy extends Component {
    hideLoadingScreen = () => {
        let element = document.getElementById("loading-screen");
        if (element)
            element.classList.remove("show");
    };
    showLoadingScreen = () => {
        let element = document.getElementById("loading-screen");
        if (element)
            element.classList.add("show");
    };

    //  TODO Its temporary solution
    waitForImagesLoad = async () => {
        return new Promise((resolve) =>
            setTimeout(
                () => {
                    resolve('result')
                },
                500
            )
        );
    };

    UNSAFE_componentWillMount() {
        this.showLoadingScreen()
    }

    async componentDidMount() {
        const data = await this.waitForImagesLoad();

        if (data !== null) {
            this.hideLoadingScreen()
        }
    }

    render() {
        let forGastronomy = css(({
            ' article': {
                padding: "100px 0 150px 0",
                textAlign: "center",
                ':nth-child(2n+0)': {
                    borderBottom: "1px solid rgba(0, 0, 0, .02)",
                    borderTop: "1px solid rgba(0, 0, 0, .02)",
                    background: "#F2F2F2"
                },
                ' img': {
                    width: "250px"
                },
                ' h1': {
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginBottom: "25px"
                },
                ' h2': {
                    fontSize: "15px",
                    fontWeight: "normal",
                    marginBottom: "15px"
                },
                ' h3': {
                    fontSize: "16px",
                    fontWeight: "600",
                    margin: 0,
                    padding: 0,
                    marginBottom: "15px"
                },
                ' .text': {
                    fontSize: "15px",
                    fontWeight: "normal",
                    marginBottom: "15px",
                    lineHeight: "1.2",
                    ' .price': {
                        marginTop: "15px",
                        fontSize: "18px",
                        fontWeight: "bold",
                        ' .value': {
                            fontSize: "20px",
                            color: "#09843E"
                        }
                    }
                },
                ' .separator': {
                    borderBottom: "1px solid rgba(0, 0, 0, .1)",
                    margin: "50px 0"
                },

                '@media (min-width: 576px)': {},
                '@media (min-width: 768px)': {
                    ' >h1': {
                        fontSize: "22px",
                    },
                    ' >h2': {
                        fontSize: "16px",
                    },
                    ' h3': {
                        fontSize: "20px",
                    },
                    ' img': {
                        width: "500px"
                    },
                },
                '@media (min-width: 992px)': {},
                '@media (min-width: 1200px)': {},
                '@media (min-width: 1500px)': {},
            },
            ' section': {
                ':nth-child(2n+0)': {
                    borderBottom: "1px solid rgba(0, 0, 0, .02)",
                    borderTop: "1px solid rgba(0, 0, 0, .02)",
                    background: "#F2F2F2"
                },

                padding: "100px 0 30px 0",
                textAlign: "center",


                ' h1': {
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginBottom: "15px"
                },
                ' h2': {
                    fontSize: "15px",
                    fontWeight: "normal",
                    marginBottom: "15px"
                },

                '@media (min-width: 576px)': {},
                '@media (min-width: 768px)': {
                    ' >h1': {
                        fontSize: "22px",
                    },
                    ' >h2': {
                        fontSize: "16px",
                    }
                },
                '@media (min-width: 992px)': {},
                '@media (min-width: 1200px)': {},
                '@media (min-width: 1500px)': {},
            },


        }));

        return (
            <main id="content" className="prints-page" {...forGastronomy}>
                <article className="container px-5 px-md-0">
                    <h1>Kasy fiskalne dla gastronomii</h1>
                    <h2 className="text-left text-md-center">Czy potrzebujesz kasy fiskalnej online? Zgodnie z ustawą od
                        1 lipca 2020 roku kasę fiskalną online są zobowiązani posiadać firmy świadczące stale lub
                        sezonowo usługi związane z wyżywieniem, stacjonarne placówki gastronomiczne oraz usługi w
                        zakresie krótkotrwałego zakwaterowania.
                        <br/><br/>
                        Sprawdź polecane przez nas kasy fiskalne online:</h2>

                    <div className="row text-left m-0 p-0">
                        <div className="col-12 col-md-5 mt-5 px-0 mt-md-0 d-flex align-items-center ">
                            <div className="text text-center text-md-left">
                                <h3>Kasa fiskalna Posnet FAWAG LITE ONLINE</h3>
                                <div className="d-flex d-md-none justify-content-center">
                                    <img
                                        src="https://www.posnet.com.pl/files/products_colors/365/frontend/---fawagliteonline-wsprzedazy.png"
                                        alt="Kasa fiskalna FAWAG LITE ONLINE"/>
                                </div>
                                <div className="d-block text-left">
                                    To pierwsza kasa produkowana przez spółkę Lubelskie Fabryki Wag FAWAG S.A. w wersji
                                    ONLINE. Jest mała, solidna i prosta w obsłudze, a także ergonomiczna i funkcjonalna.
                                    Można do niej podpiąć szufladę kasjerską, skaner kodów kreskowych, wagę, terminal
                                    płatniczy i komputer.<br/><br/>
                                    W wersji ONLINE posiada dwa porty szeregowe RS232 oraz złącze LAN/Ethernet, służące
                                    do komunikacji z Centralnym Repozytorium Kas.
                                    <div className="price">
                                        Cena netto: <span className="value">1299,00 zł</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-none col-md-7 d-md-flex align-items-center justify-content-center">
                            <img className="img-fluid img"
                                 src="https://www.posnet.com.pl/files/products_colors/365/frontend/---fawagliteonline-wsprzedazy.png"
                                 alt="Kasa fiskalna FAWAG LITE ONLINE"/>
                        </div>
                    </div>
                    <div className="separator d-block d-md-none"/>
                    <div className="row text-left m-0 p-0">
                        <div className="col-7 d-none d-md-flex align-items-center justify-content-center">
                            <img className="img img-fluid"
                                 src="https://www.novitus.pl/sites/default/files/sento900x9002.png"
                                 alt="Kasa fiskalna Novitus SENTO ONLINE"/>
                        </div>
                        <div className="col-md-5  px-0  col-12 d-flex align-items-center">
                            <div className="text">
                                <h3>Kasa fiskalna Novitus SENTO ONLINE</h3>
                                <div className="d-flex d-md-none justify-content-center">
                                    <img
                                        src="https://www.novitus.pl/sites/default/files/sento900x9002.png"
                                        alt="Kasa fiskalna Novitus SENTO ONLINE"/>
                                </div>
                                <div className="d-block text-left">
                                    Kasa SENTO Online czerpie z najlepszych wzorców i doświadczeń kas z rodziny SENTO.
                                    Optymalne połączenie wyśrubowanych parametrów technicznych oraz zaawansowanych
                                    funkcji to jej znak rozpoznawczy. Dzisiaj i za wiele lat taka sama wydajność pracy
                                    jest gwarantowana. Kasa jest dedykowana małym i średnim punktom handlowo –
                                    usługowym. Mała i poręczna obudowa kasy kryje w sobie zaawansowaną technologię i
                                    funkcjonalność spotykaną wcześniej w segmencie dużych kas systemowych.<br/><br/>
                                    SENTO Online to kasa fiskalna umożliwiająca połączenie i przesyłanie danych do
                                    Centralnego Repozytorium Kas za pośrednictwem sieci teleinformatycznej. Oznacza to,
                                    że spełnia wymagania Rozporządzenia Ministra Przedsiębiorczości i Technologii w
                                    sprawie kryteriów i warunków technicznych, którym muszą odpowiadać kasy rejestrujące
                                    oraz Ustawy o VAT.
                                    <div className="price">
                                        Cena netto: <span className="value">1750,00 zł</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="separator d-block d-md-none"/>
                    <div className="row text-left m-0 p-0">
                        <div className="col-md-5  px-0  col-12 d-flex align-items-center">
                            <div className="text">
                                <h3>Kasa fiskalna Posnet ERGO ONLINE</h3>
                                <div className="d-flex d-md-none justify-content-center">
                                    <img
                                        src="https://www.posnet.com.pl/files/products_colors/356/frontend/---wpsrzedazy-posnet-ergo-online-kasa-fiskalna-mobilna-1.png"
                                        alt="Kasa fiskalna POSNET ERGO ONLINE"/>
                                </div>
                                <div className="d-block text-left">
                                    Jedna z najbardziej popularnych kas fiskalnych na rynku Posnet ERGO teraz dostępna w
                                    wersji ONLINE. Urządzenie, dzięki modułowej konstrukcji, zapewnia użytkownikowi
                                    swobodę wyboru między przewodowym lub bezprzewodowym połączeniem z Centralnym
                                    Repozytorium Kas (CRK). <br/><br/>
                                    W dowolnym momencie możesz doposażyć Ergo w moduły
                                    komunikacyjne. Czy masz w swoim sklepie Internet i WiFi? Możesz go wykorzystać do
                                    komunikacji z CRK. Świadczysz usługi lub prowadzisz handel mobilny w terenie?
                                    Wyposaż swoją kasę w modem 3G.
                                    <div className="price">
                                        Cena netto: <span className="value">1249,00 zł</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-7 d-none d-md-flex align-items-center justify-content-center">
                            <img className="img img-fluid"
                                 src="https://www.posnet.com.pl/files/products_colors/356/frontend/---wpsrzedazy-posnet-ergo-online-kasa-fiskalna-mobilna-1.png"
                                 alt="Kasa fiskalna POSNET ERGO ONLINE"/>
                        </div>
                    </div>
                    <div className="separator d-block d-md-none"/>
                    <div className="row text-left m-0 p-0">
                        <div className="col-7 d-none d-md-flex align-items-center justify-content-center">
                            <img className="img img-fluid"
                                 src="https://www.novitus.pl/sites/default/files/next_900px_3.jpg"
                                 alt="Kasa fiskalna Novitus NEXT ONLINE"/>
                        </div>
                        <div className="col-md-5  px-0  col-12 d-flex align-items-center">
                            <div className="text">
                                <h3>Kasa fiskalna Novitus NEXT ONLINE</h3>
                                <div className="d-flex d-md-none justify-content-center">
                                    <img
                                        src="https://www.novitus.pl/sites/default/files/next_900px_3.jpg"
                                        alt="Kasa fiskalna Novitus NEXT ONLINE"/>
                                </div>
                                <div className="d-block text-left">
                                    Novitus NEXT Online to przedstawiciel nowej ery kas fiskalnych. Potwierdzeniem tej
                                    tezy jest przyznanie prestiżowego Godła Promocyjnego Teraz Polska w kategorii
                                    innowacyjnych produktów. To solidny argument przemawiający za wyborem tego
                                    urządzenia do prowadzenia swojego biznesu.<br/><br/>
                                    NEXT online należy do nowej rodziny urządzeń Novitus Online Power. Co to znaczy?
                                    Stworzyliśmy linię urządzeń o niespotykanej do tej pory mocy, na którą składają się
                                    m.in. 32 bitowe procesory o taktowaniu nawet do 524MHz, ogromna pamięć wewnętrza
                                    oraz 256MB pamięci operacyjnej. Pamięć fiskalna jest teraz 100x bardziej pojemna niż
                                    w urządzeniach z kopią elektroniczną. Bazy towarowe w urządzeniach Online Power to
                                    20 i 50 tysięcy, a nawet 1 milion kodów w wybranych urządzeniach. System LINUX
                                    gwarantuje stabilną pracę, a nasza platforma daje zapas mocy na wiele lat do przodu,
                                    niezależnie od przyszłych wyzwań. Bezpieczeństwo danych zapewnia moduł TPM oraz
                                    dwustronna autentykacja – rozwiązania sprawdzone w ponad 4 miliardach urządzeń na
                                    świecie.
                                    <div className="price">
                                        Cena netto: <span className="value">2390,00 zł</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <section className="px-5 px-md-0">
                    <div className="container">
                        <h1>Szukasz czegoś innego?</h1>
                        <h2>Oto lista polecanych przez nas kas fiskalnych oraz drukarek fiskalnych dla gastronomii.</h2>
                    </div>

                    <CashRegistersCatalog/>

                </section>
                <section className="px-5 pb-5 px-md-0">
                    <div className="container pt-4 pb-5">
                        <h1>Nadal nie znalazłeś tego, co potrzebujesz?</h1>
                        <h2>Skontaktuj się z nami a z chęcią pomożemy Ci dobrać odpowiedni sprzęt do twoich potrzeb lub
                            przejdź na stronę główną, aby zobaczyć pełny katalog produktów.</h2>
                    </div>
                </section>
            </main>
        );
    }
};