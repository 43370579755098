import React from 'react';
import {css} from "glamor";
import personImage from "../../../assets/images/person.jpg";

export default function Service(props) {

    let service = css({
        padding: "80px 0",
        ' .text': {
            // paddingLeft: "30px",
            ' h1': {
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "15px",
                textAlign: "justify"
            },
            ' h2': {
                fontSize: "14px",
                fontHeight: "600",
                marginBottom: "15px",
                textAlign: "justify"
            },
            ' h3': {
                fontSize: "13px",
                color: "rgba(0, 0, 0, .5)",
                marginBottom: "25px",
                textAlign: "justify"
            },
        },

        ' .image-container': {
            padding: "0 0 50px 0",
            // background: "red",
            ' .image': {
                overflow: "hidden",
                boxShadow: "2px 2px 10px 0 rgba(0, 0, 0, 0.10)",
                borderRadius: "999",
                height: "260px",
                width: "260px",
                ' img': {
                    width: "100% !important",
                    height: "260px",
                    backgroundPosition: "30%",
                    backgroundImage: `url('${personImage}')`,
                    backgroundSize: "cover",
                }
            }
        },

        '@media (min-width: 576px)': {},
        '@media (min-width: 768px)': {
            ' .image-container': {
                padding: "20px 0",
            },
            ' .text': {
                paddingLeft: "30px",
                ' h1': {
                    fontSize: "22px",
                    textAlign: "left"
                },
                ' h2': {
                    fontSize: "16px",
                    textAlign: "left"
                },
                ' h3': {
                    fontSize: "14px",
                    textAlign: "left"
                },
            },
        },
        '@media (min-width: 992px)': {},
        '@media (min-width: 1200px)': {},
        '@media (min-width: 1500px)': {},

    });

    return (
        <div className="bg-lightgrey">
            <section id="service" className="service container px-5 px-md-1" {...service}>
                <div className="row d-flex">
                    <div className="col-12 col-md-5">
                        <div className="image-container">
                            <div className="overflow-hidden d-flex align-items-center justify-content-center">
                                <div className="image">
                                    <img alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-7 text d-flex align-items-center">
                        <div className="center">
                            <h1>Prowadzimy autoryzowany serwis gwarancyjny i pogwarancyjny</h1>
                            <h2>Dzięki doświadczeniu, rzetelności i fachowości w wykonywaniu powierzonych nam zadań,
                                możemy się pochwalić autoryzacją prowadzenia serwisu kas i urządzeń fiskalnych
                                producentów:
                                Novitus,
                                Posnet</h2>
                            <h3>
                                Zajmiemy się także sprzętem innych producentów. Na rynku kas fiskalnych działamy już od
                                2003 roku. Pamiętaj, że kupując kasy fiskalne i inny sprzęt wybierasz także firmę
                                serwisującą.
                                Do nas możesz zadzwonić zawsze kiedy tego potrzebujesz - także po godzinach pracy
                                sklepu. <br/> <br/>Jeśli masz pytanie czy problem zadzwoń - bardzo wiele spraw naprawdę
                                można załatwić od razu przez telefon.
                            </h3>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
};