import React from 'react';
import {css} from "glamor";

export default function Item(props) {

    let item = css({
        background: "white",
        boxShadow: "2px 2px 10px 0 rgba(0, 0, 0, 0.10)",
        // height: "480px",
        maxWidth: "350px",
        padding: "20px 30px",
        margin: "10px",
        ' .wrapper': {
            height: "auto",
            minHeight: "450px"
            // background: "pink"
        },
        ' .name': {
            fontWeight: "bold",
        },
        ' .price': {
            fontWeight: "600",
            color: "rgba(9,132,62,1)",
        },
        ' .image': {
            maxHeight: "280px",
            width: "280px",
        },
        ' .description': {
            fontSize: "14px",
            color: "rgba(0, 0, 0, .5)"
        },
        ' .details-button': {
            textAlign: "right",
            fontSize: "14px",
            fontWeight: "600",
            visibility: "hidden",
            color: "rgba(9,132,62,1)",
            ':not(.disabled)': {
                visibility: "visible"
            }
        },

        '@media (min-width: 576px)': {
            ' .wrapper': {
                height: "410px",
            },
        },
        '@media (min-width: 768px)': {},
        '@media (min-width: 992px)': {},
        '@media (min-width: 1200px)': {},
        '@media (min-width: 1500px)': {},


    });

    let formatter = new Intl.NumberFormat('pl-PL', {
        style: 'currency',
        currency: 'PLN',
    });

    return (
        <div className="col-12 col-md-6 col-lg-4 p-0 d-flex justify-content-center">
            <div className="item"  {...item}>
                <div className="wrapper">
                    <div className="name">{props.item.name}</div>
                    <div className="price">{formatter.format(props.item.price)}</div>
                    <div className="overflow-hidden d-flex align-items-center justify-content-center">
                        <img src={props.item.image} alt={props.item.name} className="img-fluid image"/>
                    </div>
                    <div className="description">
                        <span>{props.item.description}</span>
                    </div>
                </div>
                <div className="details-button disabled">
                    Szczegóły
                </div>
            </div>
        </div>
    );
};