import React, {Component} from 'react';
import CashRegistersCatalog from "./cash_registers_catalog";

import {connect} from 'react-redux';
import {clearProducts, getCashRegisters, getFiscalPrinters, getPinpads} from '../../../actions';

import {CASH_REGISTERS_DEFAULT_DATA, FISCAL_PRINTERS_DEFAULT_DATA, PINPADS_DEFAULT_DATA} from "../../../actions/index";

class CashRegistersCatalogContainer extends Component {

    loadFromOutside = false;

    state = ({
        cashRegistersData: CASH_REGISTERS_DEFAULT_DATA,
        fiscalPrintersData: FISCAL_PRINTERS_DEFAULT_DATA,
        pinpadsData: PINPADS_DEFAULT_DATA,
        dataSource: "Kasy fiskalne"
    });

    dispatchFromURL = (_device, _size) => {
        switch (_device) {
            case "kasy_fiskalne": {
                let size = _size;
                if (size === "małe-mobilne") size = "małe/mobilne";
                else if (size === "średnie") size = "średnie";
                else if (size === "systemowe") size = "systemowe";
                else if (size === "wszystkie") size = "all";

                let newData = {
                    ...this.state.cashRegistersData,
                    size: size
                };

                this.setState({
                    cashRegistersData: newData,
                    fiscalPrintersData: FISCAL_PRINTERS_DEFAULT_DATA,
                    pinpadsData: PINPADS_DEFAULT_DATA,
                    dataSource: "Kasy fiskalne"
                });

                this.props.dispatch(getCashRegisters(newData));
                break;
            }
            case "drukarki_fiskalne": {
                let size = _size;
                if (size === "mobilne") size = "mobilne";
                else if (size === "stacjonarne") size = "stacjonarne";
                else if (size === "wszystkie") size = "all";

                let newData = {
                    ...this.state.fiscalPrintersData,
                    size: size
                };

                this.setState({
                    cashRegistersData: CASH_REGISTERS_DEFAULT_DATA,
                    fiscalPrintersData: newData,
                    pinpadsData: PINPADS_DEFAULT_DATA,
                    dataSource: "Drukarki fiskalne"
                });


                this.props.dispatch(getFiscalPrinters(newData));
                break;
            }
            case "terminale_płatnicze": {
                let size = _size;
                if (size === "wszystkie") size = "all";

                let newData = {
                    ...this.state.pinpadsData,
                    size: size
                };

                this.setState({
                    cashRegistersData: CASH_REGISTERS_DEFAULT_DATA,
                    fiscalPrintersData: FISCAL_PRINTERS_DEFAULT_DATA,
                    pinpadsData: newData,
                    dataSource: "Terminale płatnicze"
                });

                this.props.dispatch(getPinpads(newData));
                break;
            }
            default: {
                this.props.dispatch(getCashRegisters());
            }
        }
    };

    componentDidMount() {
        this.showLoadingOverlay();
        if (this.props.searchParams && this.props.searchParams.device && this.props.searchParams.size) {
            this.dispatchFromURL(this.props.searchParams.device, this.props.searchParams.size)
        } else
            this.props.dispatch(getCashRegisters());


    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {


        if (nextProps) {
            // console.log(nextProps);
            if (JSON.stringify(nextProps.searchParams) !== JSON.stringify(this.props.searchParams)) {
                // console.log("inne............................");
                if (nextProps.searchParams.device && nextProps.searchParams.size) {
                    this.showLoadingOverlay();
                    this.dispatchFromURL(nextProps.searchParams.device, nextProps.searchParams.size)
                } else {
                    if (nextProps.devicesList && nextProps.devicesList.rows) {
                        this.hideLoadingOverlay();
                    }
                }
            } else {
                // console.log("ERRPR");
                if (nextProps.devicesList && nextProps.devicesList.rows) {
                    this.hideLoadingOverlay();
                }
            }

        }
    }

    render() {


        return (

            <CashRegistersCatalog
                devicesList={this.props.devicesList}
                dataSource={this.state.dataSource}
                reloadData={(newData) => this.reloadData(newData)}
                changeDataSource={(newSource) => this.changeDataSource(newSource)}
            />
        );
    }

    showLoadingOverlay() {
        let overlay = document.querySelector("#productsLoadingOverlay");
        if (overlay)
            overlay.classList.add("show");
        // console.log("SHOW");
    }

    hideLoadingOverlay() {
        let overlay = document.querySelector("#productsLoadingOverlay");
        if (overlay)
            overlay.classList.remove("show");
        // console.log("HIDE");
    }

    reloadData(data) {

        this.props.dispatch(clearProducts());
        this.showLoadingOverlay();
        // Change page to 1 for every others action than pagination
        if (!data.page) data = {...data, page: 1};

        switch (this.state.dataSource) {
            case "Kasy fiskalne": {
                let newData = {
                    ...this.state.cashRegistersData,
                    ...data
                };

                this.setState({
                    cashRegistersData: newData
                });

                this.props.dispatch(getCashRegisters({...newData}));
                return;
            }
            case "Drukarki fiskalne": {
                let newData = {
                    ...this.state.fiscalPrintersData,
                    ...data
                };

                this.setState({
                    fiscalPrintersData: newData
                });
                this.props.dispatch(getFiscalPrinters({...newData}));
                return;
            }
            case "Terminale płatnicze": {
                let newData = {
                    ...this.state.pinpadsData,
                    ...data
                };

                this.setState({
                    pinpadsData: newData
                });
                this.props.dispatch(getPinpads({...newData}));
                return;
            }
            default: {
                console.error("Wrong datasource! Cant reload devices.")
            }
        }

    }

    changeDataSource(newSource) {

        this.props.dispatch(clearProducts());
        this.showLoadingOverlay();
        // console.log("change data source: " + newSource);

        this.setState({
            cashRegistersData: CASH_REGISTERS_DEFAULT_DATA,
            fiscalPrintersData: FISCAL_PRINTERS_DEFAULT_DATA,
            pinpadsData: PINPADS_DEFAULT_DATA,
            dataSource: newSource
        });

        switch (newSource) {
            case "Kasy fiskalne": {
                this.props.dispatch(getCashRegisters({...this.state.cashRegistersData}));
                return;
            }
            case "Drukarki fiskalne": {
                this.props.dispatch(getFiscalPrinters({...this.state.fiscalPrintersData}));
                return;
            }
            case "Terminale płatnicze": {
                this.props.dispatch(getPinpads({...this.state.pinpadsData}));
                return;
            }
            default: {
                console.error("Wrong datasource!")
            }
        }
    }
}

function mapStateToProps(state) {
    return {
        devicesList: state.devicesList.devicesList,
    }
}

export default connect(mapStateToProps)(CashRegistersCatalogContainer);

