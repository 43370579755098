import React, {Component} from 'react';
import {css} from "glamor";
import InfiniteCarousel from 'react-leaf-carousel';
// import Modal from "modal-simple";

export default class Reviews extends Component {

    state = {show: false};
    modalState = () => {
        this.setState({show: !this.state.show});
    };

    render() {

        let reviews = css({
            padding: "100px 0",

            ' h1': {
                fontSize: "16px",
                fontWeight: "bold",
            },
            ' .title': {
                ' .icon-box': {
                    // width: "35px",
                    fontSize: "25px",
                    marginRight: "10px",
                    color: "orange",
                    ' .fa-comments-o': {
                        marginTop: '-8px'
                    }
                },
            },
            ' .button': {
                fontSize: "11px",
                textTransform: "uppercase",
                background: "orange",
                fontWeight: "600",
                padding: "10px 15px 10px 10px",
                color: "white",
                cursor: "pointer",
                ' .icon-box': {
                    width: "20px",

                    fontSize: "16px",
                    marginRight: "5px",
                    color: "inherit",
                    ' .fa-pencil': {
                        marginTop: '-1px'
                    }
                },
            }
            ,
            ' .carousel': {
                marginTop: "20px",
                marginBottom: "0px",
                ' .stars': {
                    color: "orange",
                    ' i': {
                        margin: "2px"
                    }
                },
                ' .text': {
                    padding: "10px 0",
                    fontSize: "14px",
                    textAlign: "justify"

                },
                ' .footer': {
                    fontSize: "12px",
                    ' .author': {
                        fontWeight: "bold",
                        padding: "0 10px"
                    }
                }
            },

            '@media (min-width: 576px)': {},
            '@media (min-width: 768px)': {
                ' h1': {
                    fontSize: "22px",
                },
                ' .title': {
                    ' .icon-box': {
                        fontSize: "35px",
                        marginRight: "15px",
                        ' .fa-comments-o': {
                            marginTop: '-10px'
                        }
                    },
                },
                ' .button': {
                    fontSize: "14px",
                    padding: "15px 40px",
                    ' .icon-box': {
                        fontSize: "24px",
                        ' .fa-pencil': {
                            marginTop: '-2px'
                        }
                    },
                },
                ' .carousel': {
                    marginTop: "50px",
                    marginBottom: "20px",
                },
            },
            '@media (min-width: 992px)': {},
            '@media (min-width: 1200px)': {},
            '@media (min-width: 1500px)': {},
        });


        let loadingOverlay = css({
            top: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, .4)",
            color: "white",

            transitionDuration: "0",
            transitionProperty: "visibility, opacity",
            transitionDelay: '0',

            ":not(.show)": {
                visibility: "hidden",
                opacity: 0,
                transitionDuration: "1s",
                transitionProperty: "opacity, visibility",
                transitionDelay: '0, 1s',
            }
        });

        let renderStars = (amount) => {
            return (
                [...Array(parseInt(amount))].map((x, i) =>
                    <i key={i} className="fa fa-star"/>
                ))
        };

        let renderReviews = () => {
            // console.log(props);
            // if (!this.props.reviewsList || this.props.reviewsList.length < 1) return null;
            try {
                return (
                    this.props.reviewsList.map(function (item, i) {
                        return (
                            <div key={i}>
                                <div className="stars d-flex align-items-center">
                                    {renderStars(item.stars)}
                                </div>
                                <div className="text" style={{whiteSpace: "normal"}}>
                                    {item.review}
                                </div>
                                <div className="footer d-flex align-items-center">
                                    <div className="date">{item.date}</div>
                                    <div className="author">{item.fullName}</div>
                                </div>
                            </div>
                        )
                    })
                )
            } catch (e) {
                // console.error(e.message);
                return (
                    <div className="d-flex align-items-center justify-content-center p-2"
                         style={{height: "180px", touchAction: "none"}}>
                        Wystąpił błąd podczas ładowania opini. Spróbuj odświeżyć stronę
                    </div>
                )
            }
        };


        let renderCarousel = () => {
            if (!this.props.reviewsList || this.props.reviewsList.length < 1) return null;
            return (
                <InfiniteCarousel
                    breakpoints={[
                        {
                            breakpoint: 500,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            },
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            },
                        },
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                            },
                        },
                    ]}
                    dots={false}
                    arrows={false}
                    showSides={false}
                    sidesOpacity={1}
                    sideSize={0}
                    animationDuration={1000}
                    autoCycle={true}
                    cycleInterval={10000}
                    slidesToScroll={1}
                    slidesToShow={3}
                    scrollOnDevice={true}>

                    {renderReviews()}
                </InfiniteCarousel>
            )
        };

        let writeReviewButtonHandler = () => {
            this.modalState();

        };

        return (
            <div className="bg-lightgrey">
                <section className="reviews container px-5 px-md-0" {...reviews}>

                    <div className="title row p-0 m-0">

                        <div className="col-12 col-md-6 d-flex  p-0">
                            <div className="d-flex justify-content-center align-items-center m-0 p-0">
                                <div className="icon-box d-flex align-items-center justify-content-center ml-1">
                                    <i className="fa fa-comments-o"/>
                                </div>
                                <h1>Opinie naszych klientów</h1>
                            </div>

                        </div>

                        <div className="col-12 col-md-6 d-flex px-0 py-3 p-md-0">
                            <div className="d-flex button ml-auto justify-content-center align-items-center"
                                 onClick={writeReviewButtonHandler}>
                                <div className="icon-box d-flex align-items-center justify-content-center ml-1">
                                    <i className="fa fa-pencil"/>
                                </div>
                                <span>Napisz opinię</span>
                            </div>
                        </div>

                    </div>

                    <div className="carousel">
                        {renderCarousel()}
                        <div id="reviewsLoadingOverlay"
                             className="position-absolute d-flex align-items-center  justify-content-center" {...loadingOverlay}>
                            Loading...
                        </div>
                    </div>
                </section>

                {/*<Modal*/}
                {/*    show={this.state.show}*/}
                {/*    onHide={this.modalState}*/}
                {/*    title={{text: "Dodaj opinię"}}*/}
                {/*    closeBtn={{show: false}}*/}
                {/*    className={{*/}
                {/*        modal: "modal-block-App",*/}
                {/*        header: "header-block",*/}
                {/*        body: "body-block",*/}
                {/*        footer: "footer-block"*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <span>*/}
                {/*        Niestety, ale strona jest jeszcze w budowie i dodawanie opinii nie zostało jeszcze zaimplementowane. Jeżeli chcesz dodać własną opinię napisz ją do nas na adres email:*/}
                {/*        <b> kasy@komfox.pl</b>*/}
                {/*    </span>*/}

                {/*    <Modal.Footer cancel={{text: "Zamknij", variant: "secondary"}}/>*/}
                {/*</Modal>*/}
            </div>
        );
    }
};