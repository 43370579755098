import React from 'react';
import {css} from "glamor";
import footerBackground from "../../assets/images/footer/footer-background.jpg";

export default function Footer(props) {

    let footer = css({
        borderTop: "1px solid rgba(0, 0, 0, .1)",
        color: "white",
        overflow: "hidden",
        minHeight: "500px",
        ' h1': {
            fontSize: "20px",
            fontWeight: "bold",
            textTransform: "uppercase"
        },
        ' .contact': {
            width: "100vw",
            backgroundPosition: "30%",
            backgroundImage: `url('${footerBackground}')`,
            backgroundSize: "cover",
            padding: 0,
            ' .darkness': {
                width: "100%",
                height: "100%",
                padding: "80px 0px",
                background: "rgba(51,51,51,0.9)"
            },
            ' .separator': {
                width: "100%",
                borderBottom: "1px solid rgba(255, 255, 255, .5)",
                margin: "15px 0",
            },
            ' .main-phone': {
                fontSize: "20px",
                fontWeight: "600"
            },
            ' .address': {
                margin: "40px 0"
            }
        },
        ' .map': {
            minHeight: "500px",
        },
        ' .bottom': {
            background: "#535353",
            height: "45px",
            borderTop: "5px solid rgba(9,132,62,1)",
            fontSize: "12px"
        },

        '@media (min-width: 576px)': {
            ' h1': {
                fontSize: "22px",
            },
            ' .contact': {
                ' .darkness': {
                    // padding: "80px 120px",
                    // paddingLeft: "40% !important"
                },
            },
        },
        '@media (min-width: 768px)': {},
        '@media (min-width: 992px)': {},
        '@media (min-width: 1200px)': {},
        '@media (min-width: 1500px)': {},
    });

    return (
        <footer className="footer" {...footer}>
            <div className="row p-0 m-0">
                <div className="col-12 col-lg-5 contact p-0 m-0">
                    <div className="darkness px-5">
                        <div className="row p-0 m-0">

                            <div id="contact"
                                 className="col-12 col-sm-6 col-lg-12 col-xl-6 offset-sm-4 offset-lg-0 offset-xl-4 p-0">
                                <h1>Kontakt</h1>
                                <div className="separator"/>

                                <div className="email">email: kasy@komfox.pl</div>
                                <div className="phone main-phone">tel. +48 71 795 10 44</div>
                                <div className="phone">tel. +48 691 631 403</div>
                                <div className="phone">tel. 507 069 037</div>

                                <div className="address">
                                    <div>KOMFOX Komputery</div>
                                    <div>Środa Śląska</div>
                                    <div> ul. Legnicka 21</div>
                                    <div>55-300 Środa Śląska</div>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
                <div className="col-12 col-lg-7 map px-0">
                    <iframe
                        title="google map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5950.731775361162!2d16.588997398509893!3d51.16425935133704!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa56e1a4ebe5cf92d!2sKOMFOX%20Komputery%20Laptopy%20Kasy%20fiskalne%20Alarmy%20Monitoring!5e0!3m2!1spl!2spl!4v1573492602840!5m2!1spl!2spl"
                        width="100%" height="100%" frameBorder="0" style={{border: 0, padding: 0, margin: 0}}
                        allowFullScreen=""/>
                </div>
            </div>
            <div className="bottom d-flex justify-content-center align-items-center">
                www.kasyﬁskalne.wroc.pl 2020 © All Rights Reserved
            </div>
        </footer>
    );
};