import React, {Component} from 'react';
import {css} from "glamor";
import CashRegistersCatalog from "./cash_registers_catalog";

export default class ForCarMechanic extends Component {
    hideLoadingScreen = () => {
        let element = document.getElementById("loading-screen");
        if (element)
            element.classList.remove("show");
    };
    showLoadingScreen = () => {
        let element = document.getElementById("loading-screen");
        if (element)
            element.classList.add("show");
    };

    //  TODO Its temporary solution
    waitForImagesLoad = async () => {
        return new Promise((resolve) =>
            setTimeout(
                () => {
                    resolve('result')
                },
                500
            )
        );
    };

    UNSAFE_componentWillMount() {
        this.showLoadingScreen()
    }

    async componentDidMount() {
        const data = await this.waitForImagesLoad();

        if (data !== null) {
            this.hideLoadingScreen()
        }
    }

    render() {
        let forCarMechanic = css(({
            ' article': {
                padding: "100px 0 150px 0",
                textAlign: "center",
                ':nth-child(2n+0)': {
                    borderBottom: "1px solid rgba(0, 0, 0, .02)",
                    borderTop: "1px solid rgba(0, 0, 0, .02)",
                    background: "#F2F2F2"
                },
                ' img': {
                    width: "250px"
                },
                ' h1': {
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginBottom: "25px"
                },
                ' h2': {
                    fontSize: "15px",
                    fontWeight: "normal",
                    marginBottom: "15px"
                },
                ' h3': {
                    fontSize: "16px",
                    fontWeight: "600",
                    margin: 0,
                    padding: 0,
                    marginBottom: "15px"
                },
                ' .text': {
                    fontSize: "15px",
                    fontWeight: "normal",
                    marginBottom: "15px",
                    lineHeight: "1.2",
                    ' .price': {
                        marginTop: "15px",
                        fontSize: "18px",
                        fontWeight: "bold",
                        ' .value': {
                            fontSize: "20px",
                            color: "#09843E"
                        }
                    }
                },
                ' .separator': {
                    borderBottom: "1px solid rgba(0, 0, 0, .1)",
                    margin: "50px 0"
                },

                '@media (min-width: 576px)': {},
                '@media (min-width: 768px)': {
                    ' >h1': {
                        fontSize: "22px",
                    },
                    ' >h2': {
                        fontSize: "16px",
                    },
                    ' h3': {
                        fontSize: "20px",
                    },
                    ' img': {
                        width: "500px"
                    },
                },
                '@media (min-width: 992px)': {},
                '@media (min-width: 1200px)': {},
                '@media (min-width: 1500px)': {},
            },
            ' section': {
                ':nth-child(2n+0)': {
                    borderBottom: "1px solid rgba(0, 0, 0, .02)",
                    borderTop: "1px solid rgba(0, 0, 0, .02)",
                    background: "#F2F2F2"
                },

                padding: "100px 0 30px 0",
                textAlign: "center",


                ' h1': {
                    fontSize: "20px",
                    fontWeight: "bold",
                    marginBottom: "15px"
                },
                ' h2': {
                    fontSize: "15px",
                    fontWeight: "normal",
                    marginBottom: "15px"
                },

                '@media (min-width: 576px)': {},
                '@media (min-width: 768px)': {
                    ' >h1': {
                        fontSize: "22px",
                    },
                    ' >h2': {
                        fontSize: "16px",
                    }
                },
                '@media (min-width: 992px)': {},
                '@media (min-width: 1200px)': {},
                '@media (min-width: 1500px)': {},
            },


        }));

        return (
            <main id="content" className="prints-page" {...forCarMechanic}>
                <article className="container px-5 px-md-0">
                    <h1>Kasy fiskalne online dla mechanika samochodowego</h1>
                    <h2 className="text-left text-md-center">Czy potrzebujesz kasy fiskalnej online? Zgodnie z ustawą od
                        1 stycznia 2020 roku kasę fiskalną online są zobowiązani posiadać świadczący usługi naprawy
                        pojazdów silnikowych oraz motorowerów, w tym naprawy opon, ich zakładania, bieżnikowania i
                        regenerowania, oraz w zakresie wymiany opon lub kół dla pojazdów silnikowych oraz motorowerów.
                        Oraz sprzedający benzynę silnikową, olej napędowy, gaz przeznaczony do napędu silników
                        spalinowych.<br/><br/>
                        Sprawdź polecane przez nas kasy fiskalne online:
                    </h2>

                    <div className="row text-left m-0 p-0">
                        <div className="col-12 col-md-5 mt-5 px-0 mt-md-0 d-flex align-items-center ">
                            <div className="text text-center text-md-left">
                                <h3>Kasa fiskalna Posnet FAWAG LITE ONLINE</h3>
                                <div className="d-flex d-md-none justify-content-center">
                                    <img
                                        src="https://www.posnet.com.pl/files/products_colors/365/frontend/---fawagliteonline-wsprzedazy.png"
                                        alt="Kasa fiskalna FAWAG LITE ONLINE"/>
                                </div>
                                <div className="d-block text-left">
                                    To pierwsza kasa produkowana przez spółkę Lubelskie Fabryki Wag FAWAG S.A. w wersji
                                    ONLINE. Jest mała, solidna i prosta w obsłudze, a także ergonomiczna i funkcjonalna.
                                    Można do niej podpiąć szufladę kasjerską, skaner kodów kreskowych, wagę, terminal
                                    płatniczy i komputer.<br/><br/>
                                    W wersji ONLINE posiada dwa porty szeregowe RS232 oraz złącze LAN/Ethernet, służące
                                    do komunikacji z Centralnym Repozytorium Kas.
                                    <div className="price">
                                        Cena netto: <span className="value">1299,00 zł</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-none col-md-7 d-md-flex align-items-center justify-content-center">
                            <img className="img-fluid img"
                                 src="https://www.posnet.com.pl/files/products_colors/365/frontend/---fawagliteonline-wsprzedazy.png"
                                 alt="Kasa fiskalna FAWAG LITE ONLINE"/>
                        </div>
                    </div>
                    <div className="separator d-block d-md-none"/>
                    <div className="row text-left m-0 p-0">
                        <div className="col-7 d-none d-md-flex align-items-center justify-content-center">
                            <img className="img img-fluid"
                                 src="https://www.novitus.pl/sites/default/files/nano900x9006.png"
                                 alt="Kasa fiskalna Novitus NANO ONLINE"/>
                        </div>
                        <div className="col-md-5  px-0  col-12 d-flex align-items-center">
                            <div className="text">
                                <h3>Kasa fiskalna Novitus NANO ONLINE</h3>
                                <div className="d-flex d-md-none justify-content-center">
                                    <img
                                        src="https://www.novitus.pl/sites/default/files/nano900x9006.png"
                                        alt="Kasa fiskalna Novitus NANO ONLINE"/>
                                </div>
                                <div className="d-block text-left">
                                    Mobilna kasa fiskalna NANO online to kwintesencja ergonomii i designu, przeznaczona
                                    do pracy w mobilnych punktach handlowych, gastronomicznych i usługowych oraz
                                    wszędzie tam, gdzie trudno zmieścić większe urządzenia fiskalne. Jest to najmniejsza
                                    kasa fiskalna w ofercie NOVITUS, która jednocześnie obsługuje komunikację z
                                    Centralnym Repozytorium Kas.<br/><br/>
                                    Kasa NANO online jest najlepiej zaprojektowaną i przemyślaną kasą na polskim rynku!
                                    Swój ergonomiczny kształt oraz optymalne rozmiary, urządzenie zawdzięcza
                                    wieloletniej pracy i doświadczeniu naszych inżynierów. Wersja online łączy w sobie
                                    wszystkie zalety sprawdzonego projektu oraz nowoczesne technologiczne serce,
                                    napędzające to urządzenie.
                                    <div className="price">
                                        Cena netto: <span className="value">1490,00 zł</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <section className="px-5 px-md-0">
                    <div className="container">
                        <h1>Szukasz czegoś innego?</h1>
                        <h2>Oto lista polecanych przez nas kas fiskalnych oraz drukarek fiskalnych dla mechaników
                            samochodowych.</h2>
                    </div>

                    <CashRegistersCatalog/>

                </section>
                <section className="px-5 pb-5 px-md-0">
                    <div className="container pt-4 pb-5">
                        <h1>Nadal nie znalazłeś tego, co potrzebujesz?</h1>
                        <h2>Skontaktuj się z nami a z chęcią pomożemy Ci dobrać odpowiedni sprzęt do twoich potrzeb lub
                            przejdź na stronę główną, aby zobaczyć pełny katalog produktów.</h2>
                    </div>
                </section>
            </main>
        );
    }
};