import React from 'react';
import {css} from "glamor";
import adImage from "../../../../assets/images/ads/car-mechaic-article-image.png";

export default function CarMechanicAdd(props) {

    let carMechanicAdd = css({
        ".car-mechanic-article": {
            width: "100%",
            display: "block",
            position: "relative",
            color: "black",
            padding: "160px 0 50px 0",
            textAlign: "center",
            ' h1': {
                textTransform: "uppercase",
                fontSize: "22px",
                ' .smaller': {
                    fontWeight: "normal",
                    marginBottom: "20px",
                    fontSize: "19px"
                }
            },
            ' h2': {
                fontWeight: "normal",

                textAlign: "left",
                fontSize: "16px"
            },
            ' .separator': {
                width: "50%",
                transform: "translateX(50%)",
                borderBottom: "2px solid #F19036",
                margin: "20px 0"
            },

            '@media (min-width: 576px)': {
                textAlign: "left",
                ' h1': {
                    fontSize: "20px",
                    ' .smaller': {
                        marginBottom: "15px",
                        fontSize: "17px",
                    }
                },
                ' h2': {
                    fontSize: "13px"
                },
                ' .separator': {
                    width: "70%",
                    margin: "15px 0",
                    transform: "translateX(0)",
                },
            },
            '@media (min-width: 768px)': {
                ' h1': {
                    fontSize: "24px",
                    ' .smaller': {
                        marginBottom: "20px",
                        fontSize: "20px",
                    }
                },
                ' h2': {
                    fontSize: "15px"
                },
                ' .separator': {
                    margin: "20px 0",
                },
            },
            '@media (min-width: 992px)': {
                ' h1': {
                    fontSize: "30px",
                    ' .smaller': {
                        marginBottom: "22px",
                        fontSize: "22px",
                    }
                },
                ' h2': {
                    fontSize: "18px"
                },
                ' .separator': {
                    margin: "22px 0",
                },
            },
            '@media (min-width: 1200px)': {
                ' h1': {
                    fontSize: "35px",
                    ' .smaller': {
                        marginBottom: "30px",
                        fontSize: "26px",
                    }
                },
                ' h2': {
                    fontSize: "20px"
                },
                ' .separator': {
                    margin: "30px 0",
                },
            },
            '@media (min-width: 1500px)': {},
        },

    });

    return (
        <article className="car-mechanic-article d-flex" {...carMechanicAdd}>
            <div className="container" style={{height: "inherit"}}>
                <div className="row h-100 mx-5 mx-sm-1 mx-md-5">
                    <div className="col-12 col-sm-6 d-flex align-items-center justify-content-center">
                        <div className="text">
                            <h1>
                                <div className="smaller">Kasa fiskalna ONLINE dla mechanika</div>
                                Prowadzisz warsztat samochodowy lub serwis opon?
                            </h1>
                            <div className="separator"/>
                            <h2>
                                Zgodnie z ustawą, od 1 tycznia 2020r do rejestracji sprzedaży musisz korzystać z kasy lub drukarki fiskalnej online.
                                <br/><br/>
                                Nie wiesz, co wybrać? Sprawdź co dla Ciebie przygotowaliśmy lub skontaktuj się z nami!

                            </h2>
                        </div>
                    </div>
                    <div className="col-6 d-none d-sm-flex align-items-center justify-content-center">
                        <img src={adImage} alt="Kasa fiskalna FAWAG Lite Online - Posnet"/>
                    </div>
                </div>
            </div>
        </article>
    );
};