import React, {Component} from 'react';
//Components
import {css} from 'glamor';
import NavbarLogo from "./navbar-logo";
import ContactInformation from "./contact-information";
import MenuToggleButton from "./menu-toggle-button";
import RegularMenu from "./regular-menu";
import ProductsSubmenu from "./products-submenu";

export default class Navbar extends Component {
    state = ({
        hiddenNavbar: false,
        changedNavbar: false,
        forceVisible: false,
    });

    scroll = ({
        currentScrollTop: 0,
        previousScrollTop: 0,
        whenHide: 150,
        whenHideSmall: 30,
        whenChange: 5
    });

    menuButtonClickHandler = () => {
        this.setState(prevState => ({
            ...prevState,
            forceVisible: !!this.props.isMobileDevicesMenuHidden
        }));

        this.props.menuButtonClickHandler();
    };

    showSubmenuHandler = () => {
        let submenu = document.querySelector("#navbar .submenu");
        if (submenu)
            submenu.classList.toggle("show");

    };


    hideSubmenu = () => {
        let submenu = document.querySelector("#navbar .submenu");
        if (submenu)
            submenu.classList.remove("show");

    };

    hideMobileMenu = () => {
        this.setState(prevState => ({
            ...prevState,
            forceVisible: !!this.props.isMobileDevicesMenuHidden
        }));

        let menu = document.getElementById("mobile-devices-menu");
        menu.classList.remove("show");
    };

    render() {

        let navbar = css({
            zIndex: "2048 !important",
            position: "fixed",
            width: "100%",
            color: "black",
            background: "white",
            fontSize: "11px",
            textShadow: "1px 1px 2px rgba(0, 0, 0, 0.09)",
            boxShadow: "2px 2px 15px 0 rgba(0, 0, 0, 0.1)",
            transition: "all .6s",
            ' .navbar-content-wrapper': {
                padding: "0 25px",
                ' .navbar-menu-wrapper': {
                    height: "70px",
                },
                '@media (min-width: 576px)': {
                    padding: "0",
                    ' .navbar-menu-wrapper': {
                        height: "70px",
                    },
                },
                '@media (min-width: 768px)': {
                    padding: "0 15px",
                    ' .navbar-menu-wrapper': {
                        height: "70px",
                    },
                }
            },

        });

        let scrolledNavbar = css({
            opacity: "0",
            transform: "translateY(-70px) !important",
            '@media (min-width: 576px)': {
                transform: "translateY(-90px) !important",
            },
            '@media (min-width: 768px)': {
                transform: "translateY(-100px) !important",
            }
        });

        let changedNavbar = css({
            color: "black",
            background: "white",
            transform: `translateY(-40px)`,
        });

        return (
            <nav id="navbar" {...navbar}
                 {...(this.state.changedNavbar || (this.state.forceVisible && !this.props.isMobileDevicesMenuHidden)) && {...changedNavbar}}
                 {...(this.state.hiddenNavbar && !(this.state.forceVisible && !this.props.isMobileDevicesMenuHidden)) && {...scrolledNavbar}}>

                {/* Bootstrap container */}
                <div className="container h-100 px-0">

                    {/* Wrapper */}
                    <div className="position-relative navbar-content-wrapper h-100">

                        {/* Logo */}
                        <NavbarLogo
                            hideSubmenu={() => this.hideSubmenu()}
                            hideMobileMenu={() => this.hideMobileMenu()}
                            changedNavbar={(this.state.changedNavbar || (this.state.forceVisible && !this.props.isMobileDevicesMenuHidden))}
                            hiddenNavbar={this.state.hiddenNavbar && !this.state.forceVisible}/>

                        {/* Contact information */}
                        <ContactInformation
                            changedNavbar={(this.state.changedNavbar || (this.state.forceVisible && !this.props.isMobileDevicesMenuHidden))}/>

                        <div className="navbar-menu-wrapper d-flex align-items-center">

                            {/* Menu toggle button */}
                            <MenuToggleButton
                                changedNavbar={(this.state.changedNavbar || (this.state.forceVisible && !this.props.isMobileDevicesMenuHidden))}
                                clickHandler={this.menuButtonClickHandler}/>

                            {/* Regular devices menu */}
                            <RegularMenu
                                hideSubmenu={() => this.hideSubmenu()}
                                showSubmenuHandler={this.showSubmenuHandler}
                                changedNavbar={(this.state.changedNavbar || (this.state.forceVisible && !this.props.isMobileDevicesMenuHidden))}/>
                        </div>
                    </div>
                </div>

                <ProductsSubmenu
                    hideSubmenu={() => this.hideSubmenu()}
                />

            </nav>
        );
    }

    windowClick = (e) => {
        if (!(document.querySelector('#navbar .submenu').contains(e.target) || document.getElementById('navbar').contains(e.target))) {
            this.hideSubmenu()
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.scrollHandler);
        window.addEventListener('resize', this.resizeHandler);
        window.addEventListener('click', this.windowClick);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollHandler);
        window.removeEventListener('resize', this.resizeHandler);
        window.removeEventListener('click', this.windowClick);
    }

    resizeHandler = () => {
        this.setState(prevState => ({
            ...prevState,
            forceVisible: !this.props.isMobileDevicesMenuHidden
        }));
    };

    scrollHandler = () => {
        this.scroll.currentScrollTop = document.scrollingElement.scrollTop;

        let hiddenNavbar = this.hideNavbar();
        let changedNavbar = this.changeNavbar();

        this.setState(prevState => ({
            ...prevState,
            hiddenNavbar: hiddenNavbar,
            changedNavbar: changedNavbar
        }));

        this.scroll.previousScrollTop = this.scroll.currentScrollTop;
    };

    hideNavbar = () => {
        let state = null;
        let whenHide;

        // if landscape
        if (window.innerHeight < window.innerWidth)
            if (window.innerHeight < 500)
                whenHide = this.scroll.whenHideSmall;
            else
                whenHide = this.scroll.whenHide;


        if (this.scroll.previousScrollTop < this.scroll.currentScrollTop &&
            this.scroll.currentScrollTop > whenHide + whenHide) {
            state = true;

        } else if (this.scroll.previousScrollTop > this.scroll.currentScrollTop &&
            !(this.scroll.currentScrollTop <= whenHide)) {
            state = false;

        }

        return state;
    };

    changeNavbar = () => {
        return this.scroll.currentScrollTop > this.scroll.whenChange;
    };
}