import axios from 'axios';

const URL = '/api';

export const CASH_REGISTERS_DEFAULT_DATA = {
    limit: 6,
    page: 1,
    order: 'asc',
    orderBy: 'id',
    size: 'małe/mobilne,średnie,systemowe',
    type: "online,offline",
    producer: 'Novitus,Posnet'
};

export const FISCAL_PRINTERS_DEFAULT_DATA = {
    limit: 6,
    page: 1,
    order: 'asc',
    orderBy: 'id',
    size: 'mobilne,stacjonarne',
    type: "online,offline",
    producer: 'Novitus,Posnet'
};

export const PINPADS_DEFAULT_DATA = {
    limit: 6,
    page: 1,
    order: 'asc',
    orderBy: 'id',
    producer: 'PayTel,Posnet'
};

export function getCashRegisters(data = CASH_REGISTERS_DEFAULT_DATA) {
    const requestURL = `${URL}/cashRegisters?page=${data.page}&limit=${data.limit}&orderBy=${data.orderBy}&order=${data.order}&type=${data.type}&size=${data.size}&producer=${data.producer}`;
    const request = axios.get(requestURL)
        .then(response => response.data);

    // console.log(requestURL);
    return {
        type: 'GET_CASH_REGISTERS',
        payload: request
    }
}

export function getDevicesForCarMechanic() {
    const requestURL = `${URL}/forCarMechanic`;
    const request = axios.get(requestURL)
        .then(response => response.data);

    console.log(requestURL);
    return {
        type: 'GET_DEVICES_FOR_CAR_MECHANIC',
        payload: request
    }
}

export function getDevicesForHairdresser() {
    const requestURL = `${URL}/forHairdresser`;
    const request = axios.get(requestURL)
        .then(response => response.data);

    console.log(requestURL);
    return {
        type: 'GET_DEVICES_FOR_HAIRDRESSER',
        payload: request
    }
}

export function getDevicesForGastronomy() {
    const requestURL = `${URL}/forGastronomy`;
    const request = axios.get(requestURL)
        .then(response => response.data);

    console.log(requestURL);
    return {
        type: 'GET_DEVICES_FOR_GASTRONOMY',
        payload: request
    }
}

export function getCashRegistersProducers() {
    const requestURL = `${URL}/cashRegisters/producers`;
    const request = axios.get(requestURL)
        .then(response => response.data);

    // console.log(requestURL);
    return {
        type: 'GET_CASH_REGISTERS_PRODUCERS',
        payload: request
    }
}


export function getFiscalPrinters(data = FISCAL_PRINTERS_DEFAULT_DATA) {
    const requestURL = `${URL}/fiscalPrinters?page=${data.page}&limit=${data.limit}&orderBy=${data.orderBy}&order=${data.order}&type=${data.type}&size=${data.size}&producer=${data.producer}`;
    const request = axios.get(requestURL)
        .then(response => response.data);

    // console.log(requestURL);
    return {
        type: 'GET_FISCAL_PRINTERS',
        payload: request
    }
}


export function getPinpads(data = PINPADS_DEFAULT_DATA) {
    const requestURL = `${URL}/pinpads?page=${data.page}&limit=${data.limit}&orderBy=${data.orderBy}&order=${data.order}&producer=${data.producer}`;
    const request = axios.get(requestURL)
        .then(response => response.data);

    // console.log(requestURL);
    return {
        type: 'GET_PINPADS',
        payload: request
    }
}

export function clearProducts() {
    // console.log("CLEAR");
    return {
        type: 'CLEAR_PRODUCTS',
        payload: {}
    }
}


export function contractorsAll() {

    const request = axios.get(`${URL}/contractors`)
        .then(response => response.data);

    return {
        type: 'GET_CONTRACTORS_ALL',
        payload: request
    }
}

export function reviewsAll() {

    const request = axios.get(`${URL}/reviews`)
        .then(response => response.data);

    return {
        type: 'GET_REVIEWS_ALL',
        payload: request
    }

}