import React, {Component} from 'react';
import CashRegistersCatalog from "./cash_registers_catalog";
import AboutUs from "./about_us";
import Service from "./service";
import Contractors from "./contractors";
import Reviews from "./reviews";
import PrintsPage from "../prints_page";

export default class HomePageContent extends Component {
    hideLoadingScreen = () => {
        let element = document.getElementById("loading-screen");
        if (element)
            element.classList.remove("show");
    };

    showLoadingScreen = () => {
        let element = document.getElementById("loading-screen");
        if (element)
            element.classList.add("show");
    };

    //  TODO Its temporary solution
    waitForImagesLoad = async () => {
        return new Promise((resolve) =>
            setTimeout(
                () => {
                    resolve('result')
                },
                500
            )
        );
    };


    UNSAFE_componentWillMount() {
        this.showLoadingScreen()
    }


    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        if (nextProps.prints !== this.props.prints)
            this.showLoadingScreen()
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {

        const data = await this.waitForImagesLoad();

        if (data !== null) {
            this.hideLoadingScreen()
        }
    }

    async componentDidMount() {
        const data = await this.waitForImagesLoad();

        if (data !== null) {
            this.hideLoadingScreen()
        }
    }

    render() {
        return (
            <main>
                <section className="home-page" style={{display: `${this.props.prints ? 'none' : 'block'}`}}>

                    {/* Cash registers catalog */}
                    <CashRegistersCatalog searchParams={this.props.match.params}/>

                    {/*  About us  */}
                    <AboutUs/>

                    {/* Service */}
                    <Service/>

                    {/*  Contractors  */}
                    <Contractors/>

                    {/* Reviews */}
                    <Reviews/>
                </section>

                {/* Prints page */}
                <div style={{display: `${this.props.prints ? 'block' : 'none'}`}}>
                    <PrintsPage/>
                </div>
            </main>
        )
    }


};

