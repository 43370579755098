import React from 'react';
import {css} from "glamor";
import logoKomfox from "../../../assets/logo-komfox.png";

export default function AboutUs(props) {

    let aboutUs = css({
        padding: "80px 0",
        ' .text': {
            ' >h1': {
                fontSize: "20px",
                fontWeight: "bold",
                color: "rgba(9,132,62,1)",
                marginBottom: "15px",
                textAlign: "justify"
            },
            ' >h2': {
                fontSize: "14px",
                fontHeight: "600",
                marginBottom: "15px",
                textAlign: "justify"
            },
            ' >h3': {
                fontSize: "13px",
                color: "rgba(0, 0, 0, .5)",
                marginBottom: "25px",
                textAlign: "justify"
            },
        },
        ' .logo-container': {
            padding: "0 0 50px 0",
            borderLeft: "none",
            ' .image': {
                maxHeight: "100px",
                // width: "280px",
            }
        },


        '@media (min-width: 576px)': {},
        '@media (min-width: 768px)': {
            ' .logo-container': {
                padding: "50px 0",
                borderLeft: "1px solid rgba(0, 0, 0, .3)",
            },
            ' .text': {
                paddingRight: "50px",
                ' >h1': {
                    fontSize: "22px",
                    textAlign: "left"
                },
                ' >h2': {
                    fontSize: "16px",
                    textAlign: "left"
                },
                ' >h3': {
                    fontSize: "14px",
                    textAlign: "left"
                },
            },
        },
        '@media (min-width: 992px)': {},
        '@media (min-width: 1200px)': {},
        '@media (min-width: 1500px)': {},
    });

    return (
        <section id="about-us" className="about-us container px-5 px-md-1" {...aboutUs}>
            <div className="row">
                <div className="col-12 col-md-7 order-1 order-md-0 text ">
                    <h1>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</h1>
                    <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud</h2>
                    <h3>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. <br/><br/>Ut enim ad minim veniam, quis nostrud exercitation
                        ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore
                        eu fugiat nulla pariatur. <br/><br/>Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui
                        ofﬁcia deserunt mollit anim id est laborum.</h3>
                </div>
                <div className="col-12 col-md-5">
                    <div className="logo-container">
                        <div className="overflow-hidden d-flex align-items-center justify-content-center">
                            <img src={logoKomfox} alt="Komfox" className="img-fluid image"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};