import React from 'react';
import {css} from "glamor";
import DocumentBlock from "./document-block";
// Assets
import zmiana_serwisu_novitus from '../../assets/documants/novitus_zmiana_serwisu.pdf';
import zmiana_serwisu_posnet from '../../assets/documants/posnet_zmiana_serwisu.pdf';
import zawiadomienie_po from '../../assets/documants/zawiadomienie_podatnika_po.pdf';
import zawiadomienie_przed from '../../assets/documants/zawiadomienie_podatnika_przed.pdf';

export default function PrintsPage(props) {

    let printsPage = css(({
        ' section': {
            ':nth-child(2n+0)': {
                borderBottom: "1px solid rgba(0, 0, 0, .02)",
                borderTop: "1px solid rgba(0, 0, 0, .02)",
                background: "#F2F2F2"
            },

            padding: "100px 0 150px 0",
            textAlign: "center",

            ' h1': {
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "15px"
            },
            ' h2': {
                fontSize: "14px",
                fontHeight: "600",
                marginBottom: "15px"
            },
            ' .carousel': {
                marginTop: "30px",
                touchAction: "none !important",
                pointerEvents: "none"
            },

            '@media (min-width: 576px)': {},
            '@media (min-width: 768px)': {
                ' >h1': {
                    fontSize: "22px",
                },
                ' >h2': {
                    fontSize: "16px",
                },
            },
            '@media (min-width: 992px)': {},
            '@media (min-width: 1200px)': {},
            '@media (min-width: 1500px)': {},
        },


    }));

    return (
        <section id="druki" className="prints-page" {...printsPage}>
            <section className=" px-5 px-md-0">
                <h1>Druki do urzędu skarbowego</h1>
                <h2>Kliknij w dokument, poniżej aby go wyświetlić lub pobrać</h2>

                {/* Documents */}
                <div
                    className="d-inline-block mt-5">

                    <DocumentBlock data={{
                        file: zawiadomienie_przed,
                        name: "Zgłoszenie podatnika przed fiskalizacją"
                    }}/>

                    <DocumentBlock data={{
                        file: zawiadomienie_po,
                        name: "Zgłoszenie podatnika po fiskalizacji"
                    }}/>

                </div>
            </section>

            <section className=" px-5 px-md-0">
                <h1>Wnioski o zmianę serwisu urządzenia fiskalnego</h1>
                <h2>Kliknij w dokument, poniżej aby go wyświetlić lub pobrać</h2>

                {/* Documents */}
                <div
                    className="d-inline-block mt-5">

                    <DocumentBlock data={{
                        file: zmiana_serwisu_posnet,
                        name: "Posnet"
                    }}/>

                    <DocumentBlock data={{
                        file: zmiana_serwisu_novitus,
                        name: "Novitus"
                    }}/>

                </div>
            </section>
        </section>
    );
};