import React from 'react';
import {Link} from 'react-router-dom';
//Components
import {css} from 'glamor';

export default function RegularMenu(props) {

    let regularMenu = css({
        display: "none",

        marginTop: "13px",
        // background: "pink",

        textTransform: "uppercase",
        fontSize: "10px",
        ' .menu-list': {
            margin: "0",
            padding: "0",
            listStyleType: "none",
            ' li': {
                color: "grey",
                userSelect: "none",
                ':not(.disabled)': {
                    color: "inherit",
                    cursor: "pointer",
                }
            },
            ' .nav-link': {
                padding: "5px 8px 0 8px",
            }
        },
        ' li:hover': {
            ':not(.disabled)': {
                color: "green",
            }
        },
        '@media (min-width: 576px)': {
            display: "block"
        },
        '@media (min-width: 768px)': {
            fontSize: "11px",
            ' .menu-list': {
                ' .nav-link': {
                    padding: "0 18px",
                }
            },
        },
        '@media (min-width: 992px)': {
            fontSize: "12px",
        }
    });

    return (
        <div className="regular-menu ml-auto"
             {...regularMenu}>

            <ul className="menu-list d-flex">
                <div onClick={props.showSubmenuHandler}>
                    <li className="nav-item">
                        <span className="nav-link">Produkty</span>
                    </li>
                </div>

                <Link to="/#serwis">
                    <li className="nav-item" onClick={props.hideSubmenu}>
                        <span className="nav-link">Serwis</span>
                    </li>
                </Link>

                <Link to="/druki#druki">
                    <li className="nav-item" onClick={props.hideSubmenu}>
                        <span className="nav-link">Druki</span>
                    </li>
                </Link>

                <Link to="/#o-nas">
                    <li className="nav-item" onClick={props.hideSubmenu}>
                        <span className="nav-link">O Nas</span>
                    </li>
                </Link>

                <Link to="/#kontakt">
                    <li className="nav-item" onClick={props.hideSubmenu}>
                        <span className="nav-link">Kontakt</span>
                    </li>
                </Link>
            </ul>
        </div>
    )
};
