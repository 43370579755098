import React, {Component} from 'react';
import {css} from "glamor";
import InfiniteCarousel from 'react-leaf-carousel';

export default class Contractors extends Component {

    componentWillUnmount() {
        window.removeEventListener("resize", null)
    }

    render() {

        let props = this.props;

        let contractors = css({
            padding: "80px 0",
            textAlign: "center",
            ' h1': {
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "15px"
            },
            ' h2': {
                fontSize: "14px",
                fontHeight: "600",
                marginBottom: "15px"
            },
            ' .carousel': {
                marginTop: "30px",
                touchAction: "none !important",
                pointerEvents: "none"
            },

            '@media (min-width: 576px)': {},
            '@media (min-width: 768px)': {
                ' >h1': {
                    fontSize: "22px",
                },
                ' >h2': {
                    fontSize: "16px",
                },
            },
            '@media (min-width: 992px)': {},
            '@media (min-width: 1200px)': {},
            '@media (min-width: 1500px)': {},
        });

        let loadingOverlay = css({
            top: 0,
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, .4)",
            color: "white",

            transitionDuration: "0",
            transitionProperty: "visibility, opacity",
            transitionDelay: '0',

            ":not(.show)": {
                visibility: "hidden",
                opacity: 0,
                transitionDuration: "1s",
                transitionProperty: "opacity, visibility",
                transitionDelay: '0, 1s',
            }
        });

        let renderContractors = () => {


            try {
                return (
                    props.contractorsList.map(function (item, i) {
                        return (
                            <div key={i} className="d-flex align-items-center justify-content-center p-2"
                                 style={{height: "180px", touchAction: "none"}}>
                                <img alt={item.name}
                                     src={"/images/" + item.logoURL}/>
                            </div>
                        )
                    })
                )
            } catch (e) {
                // console.error(e.message);
                return (
                    <div className="d-flex align-items-center justify-content-center p-2"
                         style={{height: "180px", touchAction: "none"}}>
                        Wystąpił błąd podczas ładowania kontrahentów. Spróbuj odświeżyć stronę
                    </div>
                )
            }

        };

        let renderCarousel = () => {
            if (!props.contractorsList || props.contractorsList.length < 1) return null;
            return (
                <InfiniteCarousel
                    breakpoints={[
                        {
                            breakpoint: 500,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            },
                        },
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1,
                            },
                        },
                    ]}
                    dots={false}
                    arrows={false}
                    showSides={false}
                    sidesOpacity={1}
                    sideSize={.4}
                    animationDuration={2000}
                    autoCycle={true}
                    cycleInterval={3500}
                    slidesToScroll={1}
                    slidesToShow={4}
                    scrollOnDevice={false}>

                    {renderContractors()}
                </InfiniteCarousel>
            )
        };

        return (
            <section className="contractors container px-5 px-md-0" {...contractors}>
                <h1>Nasi kontrahenci</h1>
                <h2>Sprawdź sam jak wiele firm nam zaufało i zostań jedną z nich!</h2>

                <div className="carousel">
                    {renderCarousel()}
                    <div id="contractorsLoadingOverlay"
                         className="position-absolute d-flex align-items-center  justify-content-center" {...loadingOverlay}>
                        Loading...
                    </div>
                </div>
            </section>
        );
    }
};