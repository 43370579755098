import React, {Component} from 'react';
import {Route, Switch, withRouter} from "react-router-dom";

import HomePage from '../home_page';
import ForCarMechanic from "../special_pages/for_car_mechanic";
import ForHairdresser from "../special_pages/for_hairdresser";
import ForGastronomy from "../special_pages/for_gastronomy";


export default withRouter(class Routes extends Component {

    scrollHandler = () => {

        let hash = this.props.location.hash;
        let scrollTo = null;

        switch (hash) {
            case '#serwis': {
                scrollTo = document.querySelector('#service');
                break;
            }
            case '#o-nas': {
                scrollTo = document.querySelector('#about-us');
                break;
            }
            case '#kontakt': {
                scrollTo = document.querySelector('footer');
                break;
            }
            case '#produkty': {
                scrollTo = document.querySelector('#cash-registers-catalog');
                break;
            }
            case '#druki': {
                scrollTo = document.querySelector('#druki');
                break;
            }
            default : {

                switch (this.props.location.pathname) {
                    case "/dla-mechanika":{
                        scrollTo = document.querySelector('#content');
                        break;
                    }
                    case "/dla-fryzjera":{
                        scrollTo = document.querySelector('#content');
                        break;
                    }
                    case "/dla-gastronomii":{
                        scrollTo = document.querySelector('#content');
                        break;
                    }
                    default: {
                        scrollTo = document.querySelector('html');
                    }
                }
                break;
            }
        }

        if (scrollTo)
            this.animateScrollTo(
                scrollTo);
    };


    animateScrollTo = (scrollTo) => {
        // console.log(scrollTo);
        // scrollTo.scrollIntoView({behavior: 'smooth', block: 'start'});

        let yOffset;

        let top = scrollTo.getBoundingClientRect().top;
        let portrait = window.matchMedia("(orientation: portrait)");

        // console.log(portrait.matches);

        if (top >= 0 && !portrait.matches)
            yOffset = 0;
        else
            yOffset = -70;


        const y = top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    };


    componentDidMount() {
        window.addEventListener('load', this.scrollHandler);
    };

    componentWillUnmount() {
        window.removeEventListener('load', this.scrollHandler);
    }

    componentDidUpdate() {
        this.scrollHandler();
        // console.log("Update")
    };

    //  TODO Its temporary solution
    waitForImagesLoad = async () => {
        return new Promise((resolve) =>
            setTimeout(
                () => {
                    resolve('result')
                },
                1000
            )
        );
    };


    render() {
        return (
            <Switch>
                <Route path="/produkty/:device/:size" component={HomePage}/>
                <Route path="/druki" exact render={(props) => <HomePage {...props} prints={true}/>}/>
                <Route path="/dla-mechanika" exact component={ForCarMechanic}/>
                <Route path="/dla-fryzjera" exact component={ForHairdresser}/>
                <Route path="/dla-gastronomii" exact component={ForGastronomy}/>
                <Route path="/" component={HomePage}/>
            </Switch>
        );
    }
});