import React from 'react';
//Components
import {css} from 'glamor';

export default function MenuToggleButton(props) {

    let menuToggleButton = css({
        border: "1px solid rgba(0, 0, 0, 0.2)",
        borderRadius: "5px",
        textShadow: "none",
        cursor: "pointer",
        ' .icon-box': {
            height: "30px",
            width: "30px",
            fontSize: "18px",

        },
        '@media (min-width: 576px)': {
            display: "none",
        }
    });

    return (
        <div className="menu-toggle-button ml-auto" onClick={props.clickHandler}
             {...menuToggleButton}>

            <div className="icon-box d-flex align-items-center justify-content-center">
                <i className="fa fa-bars"/>
            </div>
        </div>
    )
};
