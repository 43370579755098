import React from 'react';
import {css} from "glamor";

export default function LoadingScreen(props) {

    let loadingScreen = css({
        position: "fixed",
        top: 0,
        left: 0,

        background: "rgba(255, 255, 255, .95)",
        height: "100vh",
        width: "100vw",
        zIndex: "9999",
        fontSize: "20px",

        transitionDuration: "0",
        transitionProperty: "visibility, opacity",
        transitionDelay: '0',

        ":not(.show)": {
            visibility: "hidden",
            opacity: 0,
            transitionDuration: ".5s",
            transitionProperty: "opacity, visibility",
            transitionDelay: '0, .5s',
        }
    });

    return (
        <div id="loading-screen" className="loading-screen show" {...loadingScreen}>
            <div className="d-flex h-100 w-100 align-items-center justify-content-center">
                <span>Loading...</span>
            </div>
        </div>
    );
};