import React from 'react';
import ReactDOM from 'react-dom';
import CookieConsent from "react-cookie-consent";
import {BrowserRouter} from "react-router-dom";
import Routes from "./components/routes";
import Header from "./components/header";
// styles
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'font-awesome/css/font-awesome.min.css';
// redux
import {Provider} from 'react-redux';
import {applyMiddleware, createStore} from "redux";
import promiseMiddleware from 'redux-promise';
import Reducers from './reducers';
import Footer from "./components/footer";
import LoadingScreen from "./components/loading-screen";

const createStoreWithMiddleware = applyMiddleware(promiseMiddleware)(createStore);

ReactDOM.render(
    <Provider store={createStoreWithMiddleware(Reducers)}>
        <BrowserRouter>
            <LoadingScreen/>
            <div id="website">
                <Header/>
                <Routes/>
                <Footer/>

                {/*<CookieConsent>*/}
                {/*    This website uses cookies to enhance the user experience.*/}
                {/*</CookieConsent>*/}
            </div>
        </BrowserRouter>
    </Provider>
    , document.getElementById('root'));
